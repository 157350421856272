"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoPesquisaFrenteCaixaPdvEnum;
(function (TipoPesquisaFrenteCaixaPdvEnum) {
    TipoPesquisaFrenteCaixaPdvEnum["notaFiscalSaida"] = "Nota Fiscal Sa\u00EDda";
    TipoPesquisaFrenteCaixaPdvEnum["preVenda"] = "Pr\u00E9 Venda";
    TipoPesquisaFrenteCaixaPdvEnum["consignado"] = "Consignado";
    TipoPesquisaFrenteCaixaPdvEnum["valePresente"] = "Vale Presente";
})(TipoPesquisaFrenteCaixaPdvEnum || (TipoPesquisaFrenteCaixaPdvEnum = {}));
exports.default = TipoPesquisaFrenteCaixaPdvEnum;
