import {
  FormatarDataHoraParaPtBr,
  FormatarDataParaPtBr,
  IComissoesPedidoVendaFiltroRelatorioBackend,
  IComissoesPedidoVendaFiltroRelatorioFrontend,
  IComissoesPedidoVendaPorFaturamentoComClienteCabecalhoRelatorio,
  IComissoesPedidoVendaPorFaturamentoComClienteRelatorio,
} from '@elogestor/util';
import React from 'react';
import { empresaLogada } from '../../../../../../../Hooks/Auth';
import CSV from '../../../../../../../Util/Relatorios/CSV';
import PDF from '../../../../../../../Util/Relatorios/PDF';
import Planilha from '../../../../../../../Util/Relatorios/Planilha';
import ToastInfo from '../../../../../../../Util/Toasts/ToastInfo';
import ComissoesPedidoVendaPorFaturamentoComClientePDF from './ComissoesPedidoVendaPorFaturamentoComClientePDF';
import ComissoesPedidoVendaPorFaturamentoComClientePlanilha from './ComissoesPedidoVendaPorFaturamentoComClientePlanilha';
import ComissoesPedidoVendaPorFaturamentoComClienteRelatorioComunicador from '../../../../../../../Comunicador/Comercial/Relatorios/Comissoes/ComissoesPedidoVenda/ComissoesPedidoVendaPorFaturamentoComClienteRelatorio/ComissoesPedidoVendaPorFaturamentoComClienteRelatorioComunicador';

interface IRelatorios {
  handleExportCSV(
    filtros: IComissoesPedidoVendaFiltroRelatorioFrontend
  ): Promise<void>;
  handleExportPlanilha(
    filtros: IComissoesPedidoVendaFiltroRelatorioFrontend
  ): Promise<void>;
  handleVisualizarPdf(
    filtros: IComissoesPedidoVendaFiltroRelatorioFrontend
  ): Promise<void>;
  handleObterBlobPdf(
    filtros: IComissoesPedidoVendaFiltroRelatorioFrontend
  ): Promise<Blob | undefined>;
}

const ComissoesPedidoVendaPorFaturamentoComClienteRelatorio =
  (): IRelatorios => {
    const ObterRelatorio = async (
      filtros: IComissoesPedidoVendaFiltroRelatorioFrontend,
      exibirToastPeriodoSemDados = true
    ): Promise<IComissoesPedidoVendaPorFaturamentoComClienteRelatorio | null> => {
      const filtroBack: IComissoesPedidoVendaFiltroRelatorioBackend = {
        dataEmissaoInicial: filtros.dataEmissaoInicial,
        dataEmissaoFinal: filtros.dataEmissaoFinal,
      };

      if (filtros?.listaCidade) {
        filtroBack.listaIdCidade =
          filtros.listaCidade.listaValor.map((value) => value.id) || [];
      }

      if (filtros?.listaSituacaoItem) {
        filtroBack.listaDescricaoSituacaoItem =
          await filtros.listaSituacaoItem.listaValor.map(
            (value) => value.descricao
          );
      }

      if (filtros?.listaEstado) {
        filtroBack.listaIdEstado =
          filtros.listaEstado.listaValor.map((value) => value.id) || [];
      }

      if (filtros?.listaCliente) {
        filtroBack.listaIdCliente =
          filtros.listaCliente.listaValor.map((value) => value.id) || [];
      }

      if (filtros?.listaRepresentante) {
        filtroBack.listaIdRepresentante =
          filtros.listaRepresentante.listaValor.map((value) => value.id) || [];
      }

      if (filtros?.listaModeloDocumento) {
        filtroBack.listaIdModeloDocumento =
          filtros.listaModeloDocumento.listaValor.map((value) => value.id) ||
          [];
      }

      if (filtros?.listaRamoAtividadeProfissao) {
        filtroBack.listaIdRamoAtividadeProfissao =
          filtros.listaRamoAtividadeProfissao.listaValor.map(
            (value) => value.id
          ) || [];
      }

      if (filtros?.listaEmpresa) {
        filtroBack.listaIdEmpresa =
          filtros.listaEmpresa.listaValor.map((value) => value.id) || [];
      }

      const response =
        await ComissoesPedidoVendaPorFaturamentoComClienteRelatorioComunicador.index(
          {
            params: { filtro: filtroBack },
          }
        );

      if (!response.listaDados || response.listaDados.length === 0) {
        if (exibirToastPeriodoSemDados) ToastInfo('Relatório sem dados!');
        return null;
      }

      const retorno =
        response as IComissoesPedidoVendaPorFaturamentoComClienteRelatorio;
      const listaDados = await Promise.all(
        retorno.listaDados.map((dados) => {
          const valores = {
            ...dados.valores,
            somaTotalBaseCalculoPorRepresentante: Number(
              dados.valores.somaTotalBaseCalculoPorRepresentante
            ).FormatarParaPtBr(),
            somaTotalValorPorRepresentante: Number(
              dados.valores.somaTotalValorPorRepresentante
            ).FormatarParaPtBr(),
          };

          const listaComissoesPedidoVendaItem =
            dados.listaComissoesPedidoVendaItem.map((item) => {
              return {
                ...item,
                aliquota: Number(item.aliquota).FormatarParaPtBr(),
                baseCalculo: Number(item.baseCalculo).FormatarParaPtBr(),
                valor: Number(item.valor).FormatarParaPtBr(),

                pedidoVendaDataHoraEmissao: FormatarDataParaPtBr(
                  item.pedidoVendaDataHoraEmissao
                ),
              };
            });

          return {
            ...dados,
            valores,
            listaComissoesPedidoVendaItem,
          };
        })
      );

      return {
        ...retorno,
        listaDados,
        somaTotalGeralBaseCalculo: Number(
          retorno.somaTotalGeralBaseCalculo
        ).FormatarParaPtBr(),
        somaTotalGeralValor: Number(
          retorno.somaTotalGeralValor
        ).FormatarParaPtBr(),
      };
    };

    function FormatarCabecalho(
      filtros: IComissoesPedidoVendaFiltroRelatorioFrontend,
      cabecalho: IComissoesPedidoVendaPorFaturamentoComClienteCabecalhoRelatorio
    ): IComissoesPedidoVendaPorFaturamentoComClienteCabecalhoRelatorio {
      cabecalho.possuiFiltro = false;
      cabecalho.nomeEmpresa = empresaLogada.nomeRazaoSocial ?? '';

      if (filtros.dataEmissaoInicial || filtros.dataEmissaoFinal) {
        cabecalho.possuiFiltro = true;
        cabecalho.filtroDataEmissaoInicial = '--/--/--';
        cabecalho.filtroDataEmissaoFinal = '--/--/--';

        if (filtros.dataEmissaoInicial) {
          cabecalho.filtroDataEmissaoInicial = FormatarDataHoraParaPtBr(
            filtros.dataEmissaoInicial
          );
        }

        if (filtros.dataEmissaoFinal) {
          cabecalho.filtroDataEmissaoFinal = FormatarDataHoraParaPtBr(
            filtros.dataEmissaoFinal
          );
        }
      }

      if (
        filtros.listaSituacaoItem &&
        filtros.listaSituacaoItem.listaValor.length > 0
      ) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroSituacaoItem = filtros.listaSituacaoItem.listaValor
          .map((valor: any) => {
            return valor.descricao;
          })
          .join(', ');
      }

      if (
        filtros.listaModeloDocumento &&
        filtros.listaModeloDocumento.listaValor.length > 0
      ) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroModeloDocumento =
          filtros.listaModeloDocumento.listaValor
            .map((valor: any) => {
              return valor.codigoDescricao;
            })
            .join(', ');
      }

      if (filtros.listaCliente && filtros.listaCliente.listaValor.length > 0) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroCliente = filtros.listaCliente.listaValor
          .map((valor: any) => {
            return valor.nomeRazaoSocialCodigo;
          })
          .join(', ');
      }

      if (
        filtros.listaRamoAtividadeProfissao &&
        filtros.listaRamoAtividadeProfissao.listaValor.length > 0
      ) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroRamoAtividadeProfissao =
          filtros.listaRamoAtividadeProfissao.listaValor
            .map((valor: any) => {
              return valor.descricao;
            })
            .join(', ');
      }

      if (
        filtros.listaRepresentante &&
        filtros.listaRepresentante.listaValor.length > 0
      ) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroRepresentante = filtros.listaRepresentante.listaValor
          .map((valor: any) => {
            return valor.nomeRazaoSocialCodigo;
          })
          .join(', ');
      }

      if (filtros.listaEstado && filtros.listaEstado.listaValor.length > 0) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroEstado = filtros.listaEstado.listaValor
          .map((valor: any) => {
            return valor.siglaNome;
          })
          .join(', ');
      }

      if (filtros.listaCidade && filtros.listaCidade.listaValor.length > 0) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroCidade = filtros.listaCidade.listaValor
          .map((valor: any) => {
            return valor.cidadeUf;
          })
          .join(', ');
      }

      if (filtros.listaEmpresa && filtros.listaEmpresa.listaValor.length > 0) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroEmpresa = filtros.listaEmpresa.listaValor
          .map((valor: any) => {
            return valor.nomeRazaoSocial;
          })
          .join(', ');
      }

      return cabecalho;
    }

    async function handleExportCSV(
      filtros: IComissoesPedidoVendaFiltroRelatorioFrontend
    ): Promise<void> {
      const dados = await ObterRelatorio(filtros);
      if (!dados) return;

      const relatorio = await CSV({
        campos: ComissoesPedidoVendaPorFaturamentoComClientePlanilha,
        nomeArquivo: 'ComissoesPedidoVendaPorFaturamento',
        valores: dados.listaDados,
        unwind: ['listaComissoesPedidoVendaItem'],
      });

      relatorio.AbrirArquivo();
    }

    async function handleExportPlanilha(
      filtros: IComissoesPedidoVendaFiltroRelatorioFrontend
    ): Promise<void> {
      const dados = await ObterRelatorio(filtros);
      if (!dados) return;

      const relatorio = await Planilha({
        campos: ComissoesPedidoVendaPorFaturamentoComClientePlanilha,
        nomeArquivo: 'ComissoesPedidoVendaPorFaturamento',
        valores: dados.listaDados as any,
        unwind: ['listaComissoesPedidoVendaItem'],
      });

      relatorio.AbrirArquivo();
    }

    async function handleVisualizarPdf(
      filtros: IComissoesPedidoVendaFiltroRelatorioFrontend
    ): Promise<void> {
      const dados = await ObterRelatorio(filtros);
      if (!dados) return;

      dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);
      const relatorio = await PDF({
        documento: (
          <ComissoesPedidoVendaPorFaturamentoComClientePDF dados={dados} />
        ),
      });
      relatorio.AbrirArquivo();
    }

    async function handleObterBlobPdf(
      filtros: IComissoesPedidoVendaFiltroRelatorioFrontend
    ): Promise<Blob | undefined> {
      const dados = await ObterRelatorio(filtros, false);
      if (!dados) return undefined;

      dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

      const relatorio = await PDF({
        documento: (
          <ComissoesPedidoVendaPorFaturamentoComClientePDF dados={dados} />
        ),
      });

      return relatorio.GetBlob();
    }

    return {
      handleExportCSV,
      handleExportPlanilha,
      handleVisualizarPdf,
      handleObterBlobPdf,
    };
  };

export default ComissoesPedidoVendaPorFaturamentoComClienteRelatorio();
