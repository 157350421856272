import React, { useCallback, useRef, useState } from 'react';
import {
  FormatarDataParaAmericano,
  IFechamentoPeriodoLista,
} from '@elogestor/util';
import { Dropdown } from 'react-bootstrap';
import { CgPlayListSearch } from 'react-icons/cg/index.mjs';
import { BiSave } from 'react-icons/bi';
import Lista from '../../../../../Componentes/Lista';
import IPesquisa from '../../../../../Componentes/Lista/Interface/IPesquisa';
import ITh from '../../../../../Componentes/Lista/Interface/ITh';
import PesquisaAvancada from './PesquisaAvancada';
import ListaProvider from '../../../../../Componentes/Lista/ListaProvider';
import Acoes from '../../../../../Componentes/Lista/Acoes';
import { Container } from './styles';
import SubHeader from '../../../../../Componentes/Lista/SubHeader';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import { UseLog } from '../../../../../Componentes/LogModal/Hooks/LogProvider';
import PersonalizacaoListaCamposComunicador from '../../../../../Comunicador/Configuracao/PersonalizacaoListaRotas/Campos/Comunicador/PersonalizacaoListaCamposComunicador';
import FechamentoPeriodoComunicador from '../../../../../Comunicador/Contabil/Geral/FechamentoPeriodo/FechamentoPeriodoComunicador';
import FechamentoPeriodoLogComunicador from '../../../../../Comunicador/Contabil/Geral/FechamentoPeriodo/Log/FechamentoPeriodoLogComunicador';
import BtnSalvar from '../../../../../Componentes/SubHeaderGeral/BtnSalvar';
import TextoLoading from '../../../../../Componentes/TextoLoading';
import ToastSucesso from '../../../../../Util/Toasts/ToastSucesso';
import InputTabelaDate, {
  IInputTabelaDateRef,
} from '../../../../../Componentes/Inputs/InputTabela/InputTabelaDate';

const FechamentoPeriodosLista: React.FC = () => {
  const { permissoes } = UsePermissoes();
  const { abrirJanela: abrirJanelaLog } = UseLog();

  const [loading, setLoading] = useState(false);

  const [refresh, setRefresh] = useState<boolean>(false);

  const [listaDados, setListaDados] = useState<any[]>([]);

  const listaDataRef = useRef<Array<HTMLInputElement | null>>([]);
  // const listaDataRef = useRef<Array<IInputTabelaDateRef | null>>([]);

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    const response = await FechamentoPeriodoComunicador.update({
      params: {
        listaDadosAlterar: listaDados,
      },
    });

    if (response) {
      ToastSucesso('Fechamento de Período alterado com sucesso!');
    }

    setLoading(false);
  }, [listaDados]);

  const handlePesquisarDados = useCallback(
    async ({
      pesquisaAvancada,
      order,
      descAsc,
      limite,
      pagina,
      textoPesquisa,
    }: IPesquisa) => {
      try {
        setLoading(true);

        setListaDados([]);
        listaDataRef.current = [];

        const response = await FechamentoPeriodoComunicador.index({
          params: {
            pesquisaAvancada,
            order,
            descAsc,
            limite,
            pagina,
            textoPesquisa,
          },
        });

        const dados = response.dados[0].map(
          (data: IFechamentoPeriodoLista, index: number) => {
            return {
              id: data.id,
              descricao: <div className="lista-texto">{data.descricao}</div>,
              dataFechamento: (
                <div className="lista-data">
                  <input
                    type="date"
                    defaultValue={String(data.dataFechamento)}
                    ref={(instance) => {
                      if (listaDataRef && listaDataRef.current) {
                        listaDataRef.current[index] = instance;
                      }
                    }}
                    onBlurCapture={(value: any) => {
                      const dataAtual = new Date(
                        FormatarDataParaAmericano(data.dataFechamento)
                      ).getTime();

                      const dataNova = new Date(
                        FormatarDataParaAmericano(value.target.value)
                      ).getTime();

                      if (dataNova !== dataAtual) {
                        setListaDados((prevListaDados) => {
                          const existeItem = prevListaDados.some(
                            (item) => item.id === data.id
                          );

                          if (existeItem) {
                            return prevListaDados.map((item) =>
                              item.id === data.id
                                ? {
                                    ...item,
                                    dataFechamento: FormatarDataParaAmericano(
                                      value.target.value
                                    ),
                                  }
                                : item
                            );
                          }

                          return [
                            ...prevListaDados,
                            {
                              id: data.id,
                              descricao: data.descricao,
                              dataFechamento: FormatarDataParaAmericano(
                                value.target.value
                              ),
                            },
                          ];
                        });
                      }
                    }}
                  />
                </div>
              ),
              acoes: (
                <div
                  className="lista-acoes"
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Acoes
                    // deletarFuncao={async () => {
                    //   try {
                    //     setLoading(true);

                    //     await FechamentoPeriodoComunicador.delete({
                    //       id: data.id,
                    //     });

                    //     setLoading(false);
                    //   } catch (error) {
                    //     setLoading(false);
                    //     throw error;
                    //   }
                    // }}
                    buscarLogs={async (params: any) => {
                      try {
                        setLoading(true);

                        const responseLog =
                          FechamentoPeriodoLogComunicador.show({
                            id: data.id,
                            params,
                          });

                        setLoading(false);
                        return responseLog;
                      } catch (error) {
                        setLoading(false);
                        throw error;
                      }
                    }}
                    disableDeletar
                  />
                </div>
              ),
            };
          }
        );

        setLoading(false);
        return { dados, totalPaginas: response.dados[1] };
      } catch (error) {
        TratarErros(error);
        setLoading(false);
        return { dados: [], totalPaginas: 0 };
      }
    },
    []
  );

  const handlePesquisarListaTh = useCallback(async () => {
    try {
      const response = await PersonalizacaoListaCamposComunicador.index({
        params: { nomeTabela: 'geralFechamentoPeriodo' },
      });

      const listaTh: ITh[] = [
        ...response.campos,
        {
          id: 'acao-key',
          arrastavel: false,
          nomeCampo: 'acoes',
          ordenar: false,
          tamanho: null,
          titulo: 'Ações',
          visivel: true,
          style: { width: 75 },
        },
      ];

      return listaTh;
    } catch (error) {
      TratarErros(error);
      return [];
    }
  }, []);

  return (
    <Container>
      <ListaProvider>
        <SubHeader
          titulo="Fechamento de Períodos"
          permissao={permissoes.ContabilGeralFechamentoDePeriodo}
          botaoNovo={false}
          botoes={
            <BtnSalvar onClick={handleSubmit} disabled={loading}>
              <TextoLoading loading={loading}>
                <BiSave />
                <span style={{ marginLeft: 10, fontWeight: 'bold' }}>
                  Salvar
                </span>
              </TextoLoading>
            </BtnSalvar>
          }
          configuracaoItens={
            <>
              <Dropdown.Item
                onClick={() => {
                  abrirJanelaLog(async (params) => {
                    return FechamentoPeriodoLogComunicador.index({ params });
                  });
                }}
              >
                <span style={{ display: 'flex', fontSize: 16 }}>
                  <CgPlayListSearch style={{ marginRight: 5 }} />
                  Logs
                </span>
              </Dropdown.Item>
            </>
          }
        />

        <PesquisaAvancada />

        <Lista
          pesquisarDados={handlePesquisarDados}
          pesquisarListaTh={handlePesquisarListaTh}
          detalhe={false}
          loading={loading}
          refresh={refresh}
          permitirAtualizar={async () => {
            return true;
          }}
        />
      </ListaProvider>
    </Container>
  );
};

export default FechamentoPeriodosLista;
