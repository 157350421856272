/* eslint-disable no-restricted-syntax */
import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import { IFormCiaHandles } from '@elogestor/unformcia';
import {
  FormatarDataParaPtBr,
  FormatarDataParaPtBrSemTimeZone,
  IContaValoresAlterar,
  MeioPagamentoEnum,
  PermiteLancarContaSemFormaPagamentoEnum,
  PermiteLancarContaSemPortadorEnum,
  PermiteLancarContasSemInformarCategoriaEnum,
  SituacaoAdiantamentoEnum,
  SituacaoBoletoEnum,
  TipoContaEnum,
} from '@elogestor/util';
import {
  FormProvider,
  IHandleCarregarDadosParametros,
  ISubmitProps,
} from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import GetValidationErrors from '../../../../../../Util/Erro/GetValidationErrors';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import { UseRedirecionar } from '../../../../../../Hooks/RedirecionarContext';
import ToastSucesso from '../../../../../../Util/Toasts/ToastSucesso';
import ContaComunicador from '../../../../../../Comunicador/Financeiro/Movimentacoes/Conta/Comunicador/ContaComunicador';
import { UseConfirmacao } from '../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import { UseContaListaCategoria } from './ContaListaCategoriaHook';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import { UseParametros } from '../../../../../../Hooks/ParametrosHook';
import ContaParcelaObterDataVencimentoComunicador from '../../../../../../Comunicador/Financeiro/Movimentacoes/Conta/Comunicador/ContaParcelaObterDataVencimentoComunicador';
import ToastInfo from '../../../../../../Util/Toasts/ToastInfo';
import RegraEscolhaPortadorObterConformeCriteriosComunicador from '../../../../../../Comunicador/Financeiro/Geral/RegraEscolhaPortador/Comunicador/RegraEscolhaPortadorObterConformeCriteriosComunicador';
import { Sleep } from '../../../../../../Componentes/Padrao/MenuPrincipal/Scripts';
import ToastErro from '../../../../../../Util/Toasts/ToastErro';

const FormHook: React.FC<IPadraoProps> = ({ children }) => {
  const { redirecionar } = UseRedirecionar();
  const {
    PermiteLancarContasSemInformarCategoria,
    PermiteLancarContaSemPortador,
    PermiteLancarContaSemFormaPagamento,
  } = UseParametros();
  const { abrirJanela } = UseConfirmacao();
  const {
    calcularValorTotalCategoria,
    validarListaContaParcelaCategoria,
    validarCategoriasAvista,
    atualizarCategoriasParcelaLiquidacao,

    calcularValorCampoParcelas,
  } = UseContaListaCategoria();

  const [terminouCarregarDados, setTerminouCarregarDados] = useState(false);

  const inputRefFocus = useRef<HTMLInputElement>(null);

  const handleSetarFocus = useCallback(async (): Promise<void> => {
    if (inputRefFocus.current) {
      inputRefFocus.current.focus();
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const formRef = useRef<IFormCiaHandles>(null);

  const idDetalheRegistro = useRef<string | null>(null);
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const getIdDetalheRegistro = useCallback(() => {
    return idDetalheRegistro.current;
  }, []);

  const setIdDetalheRegistro = useCallback((valor: string | null) => {
    idDetalheRegistro.current = valor;
  }, []);

  const handleCarregarDados = useCallback(
    async (
      {
        dadosDuplicados,
        dadosRecuperados,
        dadosPadrao,
        dadosObrigatorios,
      } = {} as IHandleCarregarDadosParametros
    ) => {
      const idEditar = getIdDetalheRegistro();

      async function SetarDadosObrigatorios(): Promise<void> {
        if (dadosObrigatorios) {
          const chaves = Object.keys(dadosObrigatorios) as Array<
            keyof IContaValoresAlterar
          >;

          chaves.forEach((key) => {
            const inputRef = formRef.current?.getFieldRef(key);

            if (inputRef) {
              inputRef.disabled = true;

              if (!idEditar) {
                const element = dadosObrigatorios[key];
                formRef.current?.setFieldValorInicial(key, element);
              }
            }
          });
        }
      }

      async function SetarDadosDuplicados(): Promise<void> {
        dadosDuplicados.numeroDocumento = '';
        dadosDuplicados.tipoDocumento = 'MANUAL';

        if (dadosDuplicados.listaContaCategoria.length > 0) {
          for (let i = 0; i < dadosDuplicados.listaContaCategoria.length; i++) {
            const item = dadosDuplicados.listaContaCategoria[i];
            item.id = undefined;
            item.idConta = undefined;
          }
        }

        if (dadosDuplicados.listaContaParcela.length > 0) {
          for (let i = 0; i < dadosDuplicados.listaContaParcela.length; i++) {
            const item = dadosDuplicados.listaContaParcela[i];

            item.id = undefined;
            item.idConta = undefined;
            item.numeroDocumento = '';

            if (item.listaContaParcelaLiquidacao.length > 0) {
              for (
                let j = 0;
                j < item.listaContaParcelaLiquidacao.length;
                j++
              ) {
                const valor = item.listaContaParcelaLiquidacao[j];
                valor.id = undefined;
                valor.idConta = undefined;
                valor.idContaParcela = undefined;

                if (valor.listaMovimentoPortadorCategoria.length > 0) {
                  for (
                    let k = 0;
                    k < valor.listaMovimentoPortadorCategoria.length;
                    k++
                  ) {
                    const element = valor.listaMovimentoPortadorCategoria[k];
                    element.id = undefined;
                    element.idFinanceiroMovimentoPortador = undefined;
                  }
                }
              }
            }

            if (item.listaContaParcelaCategoria.length > 0) {
              for (let j = 0; j < item.listaContaParcelaCategoria.length; j++) {
                const element = item.listaContaParcelaCategoria[j];
                element.id = undefined;
                element.idConta = undefined;
              }
            }
          }
        }

        dadosDuplicados.idOrcamento = null;
        dadosDuplicados.idPedidoVenda = null;
        dadosDuplicados.idNotaFiscalSaida = null;
        dadosDuplicados.idNotaFiscalEntrada = null;
        dadosDuplicados.idConhecimentoTransporteTerceiro = null;
        dadosDuplicados.idConhecimentoTransporte = null;
        dadosDuplicados.revertida = false;
        dadosDuplicados.ativo = true;

        setIdDetalheRegistro('');
        await formRef.current?.setDataDuplicar(dadosDuplicados);

        SetarDadosObrigatorios();

        // refresh();
      }

      async function SetarDadosRecuperados(): Promise<void> {
        SetarDadosObrigatorios();
        await formRef.current?.setDataRecuperarFormulario(dadosRecuperados);
      }

      async function SetarDadosBackend(): Promise<void> {
        if (!idEditar) return;

        const response = await ContaComunicador.show({
          id: idEditar,
        });

        if (
          response.listaContaCategoria &&
          response.listaContaCategoria.length > 0
        ) {
          validarListaContaParcelaCategoria({
            listaContaCategoria: response.listaContaCategoria,
            listaContaParcela: response.listaContaParcela,
          });
        }

        response.valorTotal = Number(response.valorTotal);
        await formRef.current?.setDataInicialSemExecutarEvento({
          ...response,
          idConta: response.id,
          listaCategoriaMudou: false,
          listaParcelaMudou: false,

          vendaPdv: response.notaFiscalSaida
            ? response.notaFiscalSaida.vendaPdv
            : false,
          situacao: response.notaFiscalSaida
            ? response.notaFiscalSaida.situacao
            : false,
        });

        SetarDadosObrigatorios();
      }

      async function SetarDadosPadrao(): Promise<void> {
        const response =
          await RegraEscolhaPortadorObterConformeCriteriosComunicador.show({
            params: {
              dataPesquisaVigencia: new Date(),
              idFormaPagamento: null,
            },
          });
        let portador: any | undefined;

        if (response && response.regraEscolhaPortador) {
          portador = {
            id: response.regraEscolhaPortador.idPortador,
            descricao: response.regraEscolhaPortador.descricao,
            portadorBoleto: response.regraEscolhaPortador.utilizaBoleto
              ? {
                  utilizaBoleto: response.regraEscolhaPortador.utilizaBoleto,
                }
              : undefined,
          };
        }

        await formRef.current?.setDataInicial({
          tipo: TipoContaEnum.pagar,
          tipoDocumento: 'MANUAL',
          dataHoraEmissao: new Date(),
          quantidadeParcelas: 1,
          valorTotal: 0,

          listaCategoriaMudou: false,
          listaParcelaMudou: false,

          valorTotalAdiantamento: 0,
          valorUtilizadoAdiantamento: 0,
          valorDisponivelAdiantamento: 0,
          situacaoAdiantamento: SituacaoAdiantamentoEnum.utilizado,

          ativo: true,
          possuiTransferencia: false,

          listaContaParcela: [
            {
              sequencia: 1,
              numeroDocumento: '',
              percentualParcela: 0,
              dataVencimento: new Date(),
              situacao: 'Aberta',
              avista: false,

              idBandeiraCartao: null,
              bandeiraCartao: null,
              idFormaPagamento: null,
              formaPagamento: null,
              idPortador: portador?.id ?? undefined,
              portador: portador ?? undefined,
              idRegraEscolhaIntegracaoPagamento: null,
              regraEscolhaIntegracaoPagamento: null,

              valorParcela: 0,
              valorTotalParcela: 0,
              valorTotalAberto: 0,
              valorTotalMovimentos: 0,
              valorJurosSoma: 0,
              valorDescontoSubtrai: 0,
              valorMultaSoma: 0,
              valorOutrasDespesasSoma: 0,
              valorOutrasDespesasSubtrai: 0,
              valorDespesasCartaoSubtrai: 0,
              valorDespesasCartorioSoma: 0,
              valorDespesasEnvioSoma: 0,
              valorComissoesSoma: 0,
              valorDespesasEnvioSubtrai: 0,
              valorComissoesSubtrai: 0,
              listaContaParcelaLiquidacao: [],
              listaContaParcelaCategoria: [],
            },
          ],

          ...dadosPadrao,
        });

        SetarDadosObrigatorios();
      }

      async function SelecionarDadosIniciais(): Promise<void> {
        try {
          setLoading(true);

          if (dadosDuplicados) {
            await SetarDadosDuplicados();
          } else if (dadosRecuperados) {
            await SetarDadosRecuperados();
          } else if (idEditar) {
            await SetarDadosBackend();
          } else {
            await SetarDadosPadrao();
          }

          setTerminouCarregarDados(true);
          handleSetarFocus();

          refresh();
          setLoading(false);
        } catch (error) {
          TratarErros(error, { redirecionar });
          setLoading(false);
        }
      }

      await SelecionarDadosIniciais();
    },
    [
      getIdDetalheRegistro,
      handleSetarFocus,
      redirecionar,
      refresh,
      setIdDetalheRegistro,
      validarListaContaParcelaCategoria,
    ]
  );

  const handleValidarListas = useCallback(
    async (data: any): Promise<boolean> => {
      const listaMensagemErro: String[] = [];

      if (data.listaContaParcela.length > 0) {
        data.listaContaParcela.forEach((item: any) => {
          if (!item.numeroDocumento) {
            listaMensagemErro.push(
              `Número do Documento da Parcela ${item.sequencia} é Obrigatório!`
            );
          }

          if (!item.dataVencimento) {
            listaMensagemErro.push(
              `Data de Vencimento da Parcela ${item.sequencia} é Obrigatória!`
            );
          }

          if (data.dataHoraEmissao && item.dataVencimento) {
            const dataHoraEmissaoSplit = FormatarDataParaPtBrSemTimeZone(
              data.dataHoraEmissao
            ).split('/');
            const dataVencimentoSplit = FormatarDataParaPtBrSemTimeZone(
              item.dataVencimento
            ).split('/');

            const novaDataHoraEmissao = new Date(
              Number(dataHoraEmissaoSplit[2]),
              Number(dataHoraEmissaoSplit[1]) - 1,
              Number(dataHoraEmissaoSplit[0])
            );

            const novaDataVencimento = new Date(
              Number(dataVencimentoSplit[2]),
              Number(dataVencimentoSplit[1]) - 1,
              Number(dataVencimentoSplit[0])
            );

            if (novaDataVencimento.getTime() < novaDataHoraEmissao.getTime()) {
              listaMensagemErro.push(
                `Data de Vencimento da Parcela ${item.sequencia} não pode ser menor que da Data de Emissão!`
              );
            }
          }

          if (item.valorTotalParcela < 0) {
            listaMensagemErro.push(
              `Valor Total da Parcela ${item.sequencia} deve ser Maior ou Igualque Zero!`
            );
          }

          if (item.valorTotalAberto < 0) {
            listaMensagemErro.push(
              `Valor Total em Aberto da Parcela ${item.sequencia} deve ser maior ou Igual a Zero!`
            );
          }

          if (item.avista) {
            if (
              !!data.dataHoraEmissao &&
              !!item.dataVencimento &&
              FormatarDataParaPtBrSemTimeZone(data.dataHoraEmissao) !==
                FormatarDataParaPtBrSemTimeZone(item.dataVencimento)
            ) {
              listaMensagemErro.push(
                `Data de Vencimento da Parcela ${item.sequencia} não pode ser Diferente da Data de Emissão, por ser A vista!`
              );
            }
          }

          if (item.listaContaParcelaLiquidacao) {
            item.listaContaParcelaLiquidacao.forEach(
              (valor: any, index: number) => {
                if (!valor.idPortador && !valor.idContaAdiantamento) {
                  listaMensagemErro.push(
                    `Portador da ${index + 1}ª Liquidação da Parcela ${
                      item.sequencia
                    } é Obrigatório!`
                  );
                }
                if (!valor.idFormaPagamento && !valor.idContaAdiantamento) {
                  listaMensagemErro.push(
                    `Forma de Pagamento da ${
                      index + 1
                    }ª Liquidação da Parcela ${item.sequencia} é Obrigatória!`
                  );
                }
                // if (valor.valor < 0) {
                //   listaMensagemErro.push(
                //     `Valor da ${index + 1}ª da Liquidação da Parcela ${
                //       item.sequencia
                //     } deve ser Maior ou Igual a Zero!`
                //   );
                // }
              }
            );
          }
        });

        const valorTotalDasParcelas = data.listaContaParcela
          .reduce((acc: any, item: any) => {
            acc += Number(item.valorParcela);
            return acc;
          }, 0)
          .Arredondar();

        if (valorTotalDasParcelas !== data.valor) {
          listaMensagemErro.push(
            `Soma dos Valores das Parcelas é Diferente do Valor da Conta!`
          );
        }
      }

      if (data.listaContaCategoria.length > 0) {
        const valorTotalCategoria = await calcularValorTotalCategoria(
          data.listaContaCategoria
        );

        if (data.valorTotal !== valorTotalCategoria) {
          listaMensagemErro.push(
            `Valor Total das Categorias é Diferente do Valor Total da Conta!`
          );
        }
      }

      if (listaMensagemErro.length > 0) {
        TratarErros({ listaMensagem: listaMensagemErro });
        return false;
      }

      return true;
    },
    [calcularValorTotalCategoria]
  );

  const handleValidar = useCallback(
    async (data: any): Promise<boolean> => {
      try {
        formRef.current?.setErrors({});
        const tipo =
          formRef.current?.getFieldValue('tipo') === TipoContaEnum.receber;

        const possuiBoleto = formRef.current
          ?.getFieldValue('listaContaParcela')
          .some(
            (e: any) =>
              e.formaPagamento?.meioPagamento ===
                MeioPagamentoEnum.boletoBancario &&
              e.portador?.portadorBoleto?.utilizaBoleto
          );

        const valoresCamposParcela = calcularValorCampoParcelas(
          data.listaContaParcela
        );

        let possuiValoresMaiorQueZero = false;

        for (const chave in valoresCamposParcela) {
          if (valoresCamposParcela[chave] > 0) possuiValoresMaiorQueZero = true;
        }

        const schema = Yup.object().shape({
          idPessoa: Yup.string()
            .nullable()
            .test({
              message: 'Conta deve ter um Cliente informado!',
              test: (value: any) => {
                return !(tipo && possuiBoleto && !value);
              },
            }),

          numeroDocumento: Yup.string()
            .max(20, 'Número do Documento deve ter no Máximo 20 Carácteres!')
            .required('Número do Documento é Obrigatório!'),
          tipoDocumento: Yup.string()
            .max(20)
            .required('Tipo do Documento é Obrigatório!'),
          dataHoraEmissao: Yup.date()
            .required('Data Hora e Emissão é Obrigatória!')
            .nullable(),
          valor: Yup.number()
            .required('Valor Conta é Obrigatório!')
            .test({
              message: 'Valor da Conta deve ser Maior que Zero!',
              test: (value: any) => {
                if (
                  Number(value) === 0 &&
                  Number(data.valorTotal) <= 0 &&
                  !possuiValoresMaiorQueZero
                )
                  return false;

                return true;
              },
            }),
          valorTotal: Yup.number()
            .required('Valor Total da Conta é Obrigatório!')
            .test({
              message: 'Valor Total da Conta deve ser Maior ou Igual a Zero!',
              test: (value: any) => {
                if (Number(value) < 0) return false;

                return true;
              },
            }),
          tipo: Yup.string().nullable().required('Tipo é Obrigatório!'),
          valorDisponivelAdiantamento: Yup.number().test({
            message:
              'Valor Disponível Adiantamento deve ser Maior ou Igual a Zero!',
            test: (value: any) => {
              if (Number(value) < 0) return false;

              return true;
            },
          }),
        });

        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        const errors = GetValidationErrors(error);
        formRef.current?.setErrors(errors);
        return false;
      }
    },
    [calcularValorCampoParcelas]
  );

  const handleAlterarValorConta = useCallback(
    async (data: any) => {
      const valorConta = formRef.current?.getFieldValue('valor');

      const somaValorParcelas = data.listaContaParcela
        .reduce((acc: number, item: any) => {
          acc += Number(item.valorParcela);
          return acc;
        }, 0)
        .Arredondar();

      if (somaValorParcelas !== valorConta) {
        const resposta = await abrirJanela({
          titulo: <h2>Confirmação</h2>,
          mensagem: (
            <span style={{ fontSize: 18 }}>
              {`Soma das Parcelas é R$${Number(
                somaValorParcelas
              ).FormatarParaPtBr()}, deseja atualizar o Valor da Conta para R$${Number(
                somaValorParcelas
              ).FormatarParaPtBr()}?`}
            </span>
          ),
          confimar: 'Sim',
          cancelar: 'Não',
        });

        if (!resposta) {
          return;
        }

        formRef.current?.setFieldValue('valor', somaValorParcelas);
        data.valor = somaValorParcelas;

        refresh();
        await Sleep(1000);
        refresh();
      }
    },
    [abrirJanela, refresh]
  );

  const handleAtualizarListaContaParcelaDataVencimento = useCallback(
    async (data: any) => {
      try {
        const listaIdContaParcela = data.listaContaParcela?.map((item: any) => {
          return item.id;
        });

        const response = await ContaParcelaObterDataVencimentoComunicador.index(
          { params: { listaIdContaParcela } }
        );

        for (let j = 0; j < response.length; j++) {
          const { sequencia, dataVencimento } = response[j];

          const parcelaSelecionada = data.listaContaParcela.find(
            (e: any) => e.sequencia === sequencia
          );
          if (
            response[j].boleto &&
            response[j].boleto.situacao === SituacaoBoletoEnum.registrado &&
            FormatarDataParaPtBr(parcelaSelecionada.dataVencimento) !==
              FormatarDataParaPtBr(dataVencimento)
          ) {
            const resposta = await abrirJanela({
              titulo: <h2>Confirmação</h2>,
              mensagem: (
                <span style={{ fontSize: 18 }}>
                  {`Será enviada para o banco a instrução: Alteração do Vencimento da
                 Parcela ${sequencia} de ${FormatarDataParaPtBr(
                   dataVencimento
                 )} para ${FormatarDataParaPtBr(
                   parcelaSelecionada.dataVencimento
                 )}. Deseja continuar?`}
                </span>
              ),
              cancelar: 'Não',
              confimar: 'Sim',
            });

            if (!resposta) {
              formRef.current?.setFieldValue('dataVencimento', dataVencimento);
              parcelaSelecionada.dataVencimento = dataVencimento;

              ToastInfo('Alteração da data de vencimento não foi salva!');

              setLoading(false);
              return;
            }
          }
        }
      } catch (error) {
        setLoading(false);
        TratarErros(error, { redirecionar });
      }
    },
    [abrirJanela, redirecionar]
  );

  const handleSubmit = useCallback(
    async (data: any): Promise<ISubmitProps> => {
      try {
        setLoading(true);
        let id = getIdDetalheRegistro() || '';
        const { naoValidaCategoriaInformada } = data;

        await handleAlterarValorConta(data);

        data = formRef.current?.getData();
        data.naoValidaCategoriaInformada = naoValidaCategoriaInformada;

        await atualizarCategoriasParcelaLiquidacao({
          listaContaCategoria: data.listaContaCategoria,
          listaContaParcela: data.listaContaParcela,
        });

        if (!(await handleValidar(data))) {
          setLoading(false);
          return { id, erro: true };
        }

        await handleAtualizarListaContaParcelaDataVencimento(data);

        if (!(await handleValidarListas(data))) {
          setLoading(false);
          return { id, erro: true };
        }

        if (
          data.listaContaCategoria &&
          data.listaContaCategoria.length > 0 &&
          data.listaContaParcela &&
          data.listaContaParcela.length > 0
        ) {
          const { listaMensagemErro } = await validarListaContaParcelaCategoria(
            {
              listaContaCategoria: data.listaContaCategoria,
              listaContaParcela: data.listaContaParcela,
            }
          );

          if (listaMensagemErro.length > 0) {
            TratarErros({ listaMensagem: listaMensagemErro });
            setLoading(false);
            return { id, erro: true };
          }

          const { listaMensagemErro: listaMensagem } =
            await validarCategoriasAvista({
              listaContaCategoria: data.listaContaCategoria,
              listaContaParcela: data.listaContaParcela,
            });

          if (listaMensagem.length > 0) {
            TratarErros({ listaMensagem });
            setLoading(false);
            return { id, erro: true };
          }

          await atualizarCategoriasParcelaLiquidacao({
            listaContaCategoria: data.listaContaCategoria,
            listaContaParcela: data.listaContaParcela,
          });
        }

        if (!data.naoValidaCategoriaInformada) {
          if (
            PermiteLancarContasSemInformarCategoria ===
              PermiteLancarContasSemInformarCategoriaEnum.naoPermitir &&
            data.listaContaCategoria.length === 0
          ) {
            ToastErro('Categoria é Obrigatória!');
            setLoading(false);
            return { id, erro: true };
          }

          if (
            PermiteLancarContasSemInformarCategoria ===
              PermiteLancarContasSemInformarCategoriaEnum.permitirGerarAviso &&
            data.listaContaCategoria.length === 0
          ) {
            ToastInfo('Conta salva, mas Categoria não Informada!');
          }
        }

        const portadorNaoPreenchido = data.listaContaParcela.some(
          (e: any) => e.portador === null
        );

        const formaPagamentoNaoPreenchida = data.listaContaParcela.some(
          (e: any) => e.formaPagamento === null
        );

        if (portadorNaoPreenchido) {
          if (
            PermiteLancarContaSemPortador ===
            PermiteLancarContaSemPortadorEnum.naoPermitir
          ) {
            ToastErro('Portador é Obrigatório em todas as parcelas!');
            setLoading(false);
            return { id, erro: true };
          }

          if (
            PermiteLancarContaSemPortador ===
            PermiteLancarContaSemPortadorEnum.permitirGerarAviso
          ) {
            ToastInfo('Conta salva, mas portador não Informado!');
          }
        }

        if (formaPagamentoNaoPreenchida) {
          if (
            PermiteLancarContaSemFormaPagamento ===
            PermiteLancarContaSemFormaPagamentoEnum.naoPermitir
          ) {
            ToastErro('Forma de pagamento é Obrigatória em todas as parcelas!');
            setLoading(false);
            return { id, erro: true };
          }

          if (
            PermiteLancarContaSemFormaPagamento ===
            PermiteLancarContaSemFormaPagamentoEnum.permitirGerarAviso
          ) {
            ToastInfo('Conta salva, mas Forma de pagamento não Informada!');
          }
        }

        if (id) {
          await ContaComunicador.update({ id: data.id, params: data });
        } else {
          const response = await ContaComunicador.store({ params: data });
          setIdDetalheRegistro(response.id);
          formRef.current?.setFieldValue('idConta', response.idConta);
          formRef.current?.setFieldValue('id', response.idConta);
          id = response.id;
        }

        await formRef.current?.atualizarDataInicial();
        ToastSucesso('Registro Salvo!');
        setLoading(false);
        return { id, erro: false };
      } catch (error) {
        TratarErros(error, { redirecionar });
        setLoading(false);
        return { id: '', erro: true };
      }
    },
    [
      getIdDetalheRegistro,
      handleAlterarValorConta,
      atualizarCategoriasParcelaLiquidacao,
      handleValidar,
      handleAtualizarListaContaParcelaDataVencimento,
      handleValidarListas,
      validarListaContaParcelaCategoria,
      validarCategoriasAvista,
      PermiteLancarContasSemInformarCategoria,
      PermiteLancarContaSemPortador,
      PermiteLancarContaSemFormaPagamento,
      setIdDetalheRegistro,
      redirecionar,
    ]
  );

  return (
    <FormProvider
      value={{
        terminouCarregarDados,
        inputRefFocus,
        handleSetarFocus,
        formRef,
        getIdDetalheRegistro,
        setIdDetalheRegistro,
        loading,
        setLoading,
        handleValidar,
        handleSubmit,
        handleCarregarDados,
        refresh,
      }}
    >
      {children}
    </FormProvider>
  );
};

export default FormHook;
