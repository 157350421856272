import {
  IContaParcelaValoresInserir,
  IMovimentoPortadorValoresInserir,
  MeioPagamentoEnum,
  SituacaoAdiantamentoEnum,
  TipoAdiantamentoEnum,
} from '@elogestor/util';
import React, { createContext, useCallback, useContext } from 'react';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';

interface IAntecipacaoPrevisaoContext {
  validador: boolean;

  atualizaAntecipacaoPrevisao(): Promise<void>;
}

const AntecipacaoPrevisaoContext = createContext<IAntecipacaoPrevisaoContext>(
  {} as IAntecipacaoPrevisaoContext
);

const AntecipacaoPrevisaoHook: React.FC<IPadraoProps> = ({ children }) => {
  const { formRef } = UseForm();

  const handleAtualizaAntecipacaoPrevisao =
    useCallback(async (): Promise<void> => {
      const isAdiantamentoAntecipacao = formRef.current?.getFieldValue(
        'isAdiantamentoAntecipacao'
      );

      if (isAdiantamentoAntecipacao) {
        const listaContaParcela = formRef.current?.getFieldValue(
          'listaContaParcela'
        ) as IContaParcelaValoresInserir[];

        const valorUtilizadoAdiantamento = formRef.current?.getFieldValue(
          'valorUtilizadoAdiantamento'
        );

        const listaContaParcelaValePresente = listaContaParcela.filter(
          (e) =>
            e.formaPagamento &&
            e.formaPagamento.meioPagamento === MeioPagamentoEnum.valePresente
        );

        const listaContaParcelaComLiquidacao = listaContaParcela
          .filter(
            (e) =>
              !e.formaPagamento ||
              e.formaPagamento.meioPagamento !== MeioPagamentoEnum.valePresente
          )
          .filter(
            (e) =>
              e.listaContaParcelaLiquidacao &&
              e.listaContaParcelaLiquidacao.length > 0
          );

        const valorValePresente = listaContaParcelaValePresente?.reduce(
          (acumulador: number, item: IContaParcelaValoresInserir) => {
            acumulador += Number(item.valorParcela);
            return acumulador;
          },
          0
        );

        const valorSomaLiquidacao = listaContaParcelaComLiquidacao?.reduce(
          (acumulador: number, item: IContaParcelaValoresInserir) => {
            const valorTotalLiquidacao =
              item.listaContaParcelaLiquidacao?.reduce(
                (
                  acumuladorLiquidacao: number,
                  liquidacao: IMovimentoPortadorValoresInserir
                ) => {
                  acumuladorLiquidacao += Number(liquidacao.valorGeral);
                  return acumuladorLiquidacao;
                },
                0
              );

            acumulador += Number(valorTotalLiquidacao);
            return acumulador;
          },
          0
        );

        const valorConta = formRef.current.getFieldValue('valor');
        const tipoAdiantamento =
          formRef.current.getFieldValue('tipoAdiantamento');

        let valorTotalAdiantamento = 0;

        if (
          valorConta > 0 &&
          tipoAdiantamento === TipoAdiantamentoEnum.valePresente
        ) {
          valorTotalAdiantamento = valorConta;
        } else {
          valorTotalAdiantamento = valorValePresente + valorSomaLiquidacao;
        }

        const valorDisponivelAdiantamento =
          valorTotalAdiantamento - Number(valorUtilizadoAdiantamento);

        let situacaoAdiantamento = SituacaoAdiantamentoEnum.disponivel;

        if (valorDisponivelAdiantamento === 0) {
          situacaoAdiantamento = SituacaoAdiantamentoEnum.utilizado;
        } else if (
          Number(valorUtilizadoAdiantamento) > 0 &&
          Number(valorUtilizadoAdiantamento) !== valorTotalAdiantamento
        ) {
          situacaoAdiantamento = SituacaoAdiantamentoEnum.parcialmenteUtilizado;
        } else {
          situacaoAdiantamento = SituacaoAdiantamentoEnum.disponivel;
        }

        formRef.current?.setFieldValorInicialSemExecutarEvento(
          'valorTotalAdiantamento',
          valorTotalAdiantamento
        );
        formRef.current?.setFieldValorInicialSemExecutarEvento(
          'valorDisponivelAdiantamento',
          valorDisponivelAdiantamento
        );
        formRef.current?.setFieldValorInicialSemExecutarEvento(
          'situacaoAdiantamento',
          situacaoAdiantamento
        );

        listaContaParcelaComLiquidacao.forEach((item) => {
          item.listaContaParcelaLiquidacao?.forEach((value) => {
            const { idContaAdiantamento } = value;

            const valorDisponivelAdiantamentoAtual =
              valorSomaLiquidacao - valorDisponivelAdiantamento;

            if (valorDisponivelAdiantamento && idContaAdiantamento) {
              formRef.current?.setFieldValorInicialSemExecutarEvento(
                'valorDisponivelAdiantamento',
                valorDisponivelAdiantamentoAtual
              );
              formRef.current?.setFieldValorInicialSemExecutarEvento(
                'situacaoAdiantamento',
                SituacaoAdiantamentoEnum.utilizado
              );
              formRef.current?.setFieldValorInicialSemExecutarEvento(
                'valorUtilizadoAdiantamento',
                valorDisponivelAdiantamento
              );
            }
          });
        });
      }
    }, [formRef]);

  return (
    <AntecipacaoPrevisaoContext.Provider
      value={{
        atualizaAntecipacaoPrevisao: handleAtualizaAntecipacaoPrevisao,

        validador: true,
      }}
    >
      {children}
    </AntecipacaoPrevisaoContext.Provider>
  );
};

function UseAntecipacaoPrevisao(): Omit<
  IAntecipacaoPrevisaoContext,
  'validador'
> {
  const context = useContext(AntecipacaoPrevisaoContext);

  if (!context.validador) {
    throw new Error(
      'UseAntecipacaoPrevisao deve ser usado com um AntecipacaoPrevisaoHook'
    );
  }

  return context;
}

export { AntecipacaoPrevisaoHook, UseAntecipacaoPrevisao };
