import React, { useCallback } from 'react';
import { Dropdown } from 'react-bootstrap';
import { v4 } from 'uuid';
import { IoMdAddCircleOutline } from 'react-icons/io/index.mjs';
import SplitAcoes from '../../SubHeaderGeral/SplitAcoes';
import { UseReactSizeMeBodyHook } from '../../../Hooks/ReactSizeMeBodyHook';

interface IProps {
  onClick?: (valor: string) => void;
}

const BntVariaveisButton: React.FC<IProps> = ({ onClick }) => {
  const { telaGrande } = UseReactSizeMeBodyHook();

  const handleClick = useCallback(
    (valor: string) => {
      if (onClick) onClick(valor);
    },
    [onClick]
  );

  return (
    <div style={{ height: '100%', display: 'flex', alignItems: 'end' }}>
      <SplitAcoes
        id={v4()}
        className="btn-group"
        title={
          <div>
            <IoMdAddCircleOutline />
            {telaGrande && (
              <span style={{ marginLeft: 10, marginRight: 10 }}>
                Adicionar Variável
              </span>
            )}
          </div>
        }
      >
        <Dropdown.Item onClick={() => handleClick('<#BaseIcmsSt#>')}>
          <span style={{ display: 'flex', fontSize: 16 }}>
            Base de Cálculo do ICMS ST
          </span>
        </Dropdown.Item>

        <Dropdown.Item onClick={() => handleClick('<#ValorIcmsSt#>')}>
          <span style={{ display: 'flex', fontSize: 16 }}>
            Valor do ICMS ST
          </span>
        </Dropdown.Item>

        <Dropdown.Item onClick={() => handleClick('<#ValorTotalPisRetido#>')}>
          <span style={{ display: 'flex', fontSize: 16 }}>
            Valor do Pis Retido
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          onClick={() => handleClick('<#ValorTotalCofinsRetido#>')}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            Valor do Cofins Retido
          </span>
        </Dropdown.Item>

        <Dropdown.Item onClick={() => handleClick('<#PercentualCredito#>')}>
          <span style={{ display: 'flex', fontSize: 16 }}>
            Percentual de Crédito do ICMS SN
          </span>
        </Dropdown.Item>

        <Dropdown.Item onClick={() => handleClick('<#ValorCredito#>')}>
          <span style={{ display: 'flex', fontSize: 16 }}>
            Valor de Crédito do ICMS SN
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          onClick={() => handleClick('<#ValorTotalIcmsFundoCombatePobrezaST#>')}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            Valor total do FCP do ICMS ST
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          onClick={() => handleClick('<#ValorTotalIcmsSemDiferimento#>')}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            Valor do ICMS sem Diferimento
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          onClick={() => handleClick('<#ValorTotalIcmsDiferimento#>')}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            Valor total do Diferimento do ICMS
          </span>
        </Dropdown.Item>

        <Dropdown.Item onClick={() => handleClick('<#PercentualDiferimento#>')}>
          <span style={{ display: 'flex', fontSize: 16 }}>
            Percentual de Diferimento do ICMS
          </span>
        </Dropdown.Item>

        <Dropdown.Item onClick={() => handleClick('<#AliquotaFunrural#>')}>
          <span style={{ display: 'flex', fontSize: 16 }}>
            Alíquota Funrural
          </span>
        </Dropdown.Item>

        <Dropdown.Item onClick={() => handleClick('<#ValorFunrural#>')}>
          <span style={{ display: 'flex', fontSize: 16 }}>Valor Funrural</span>
        </Dropdown.Item>

        <Dropdown.Item
          onClick={() =>
            handleClick('<#ValorTotalIcmsOperacaoInterestadualEstadoDestino#>')
          }
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            Valor Total do Icms Op. Inter. Est. Destino
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          onClick={() =>
            handleClick(
              '<#ValorTotalIcmsOperacaoInterestadualEstadoDestinoFcp#>'
            )
          }
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            Valor Total FCP do Icms Op. Inter. Est. Destino
          </span>
        </Dropdown.Item>

        <Dropdown.Item onClick={() => handleClick('<#BaseIcmsStRetido#>')}>
          <span style={{ display: 'flex', fontSize: 16 }}>
            Base de Cálculo do ICMS ST Retido
          </span>
        </Dropdown.Item>

        <Dropdown.Item onClick={() => handleClick('<#ValorIcmsStRetido#>')}>
          <span style={{ display: 'flex', fontSize: 16 }}>
            Valor do ICMS ST Retido
          </span>
        </Dropdown.Item>
      </SplitAcoes>
    </div>
  );
};

export default BntVariaveisButton;
