import React, { createContext, useState, useContext, useEffect } from 'react';
import Carregando from '../Componentes/Carregando';
import IPadraoProps from '../Comum/Interface/IPadraoProps';
import UsuarioPermissoesComunicador from '../Comunicador/Configuracao/Usuario/Comunicador/UsuarioPermissoesComunicador';
import UsuarioTipoComunicador from '../Comunicador/Configuracao/Usuario/Comunicador/UsuarioTipoComunicador';
import TratarErros from '../Util/Erro/TratarErros';
import { UseAuth } from './Auth';
import IPermissoes from './Interfaces/IPermissoes';

export type TipoUsuario =
  | 'Elo Gestor'
  | 'Gestor'
  | 'Colaborador'
  | 'Contabilidade'
  | 'Representante'
  | '';

interface IPermissoesContextData {
  tipoUsuario: TipoUsuario;
  permissoes: IPermissoes;
}

const PermissoesContext = createContext<IPermissoesContextData>(
  {} as IPermissoesContextData
);

const PermissoesProvider: React.FC<IPadraoProps> = ({ children }) => {
  const { usuario } = UseAuth();
  const [tipoUsuario, setTipoUsuario] = useState<TipoUsuario>('');
  const [permissoes, setPermissoes] = useState<IPermissoes>({
    SuprimentosProdutosJuncaoProdutos: undefined,
    ComercialPessoasJuncaoPessoas: undefined,
    ComercialComissaoRegraCalculoComissao: undefined,
    ComercialPessoasPessoa: undefined,
    ComercialPessoasRamoAtividadeProfissao: undefined,
    ComercialPessoasFinalidadeUsoCliente: undefined,
    ComercialPessoasImportacaoExportacaoCsvPessoas: undefined,
    ComercialRelatoriosPessoa: undefined,
    ComercialRelatoriosVendasCurvaAbcNotaFiscalSaida: undefined,
    ComercialRelatoriosVendasCurvaAbcPedidoVenda: undefined,
    ComercialRelatoriosVendasNotaFiscalSaida: undefined,
    ComercialRelatoriosVendasOrcamento: undefined,
    ComercialRelatoriosVendasPedidoVenda: undefined,
    ComercialTabelaPrecoRegraEscolhaTabelaPreco: undefined,
    ComercialVendasNotaFiscalSaida: undefined,
    ComercialVendasOrcamento: undefined,
    ComercialVendasPedidoVenda: undefined,
    ComercialVendasLogsIntegracaoPedidos: undefined,
    ComercialFrenteCaixaPdv: undefined,
    ComercialVendasImpressaoEtiquetasNotaFiscal: undefined,
    ComercialVendasImpressaoEtiquetasPedidoVenda: undefined,
    ComercialVendasImpressaoEtiquetasPedidoVendaItem: undefined,
    ComercialVendasAberturaFechamentoCaixa: undefined,
    ComercialVendasTerminalCaixa: undefined,

    ConfiguracaoCodigoESequencia: undefined,
    ConfiguracaoContaDeEmail: undefined,
    ConfiguracaoEmpresaDetalhe: undefined,
    ConfiguracaoEtiqueta: undefined,
    ConfiguracaoParametroDetalhe: undefined,
    ConfiguracaoUsuario: undefined,
    ConfiguracaoIntegracaoEmpresaCrmSimples: undefined,
    ConfiguracaoIntegracaoEmpresaTray: undefined,

    ContabilContasContabeisCentroCusto: undefined,
    ContabilContasContabeisContaContabil: undefined,
    ContabilGeralContabilista: undefined,

    ContabilGeralFechamentoDePeriodo: undefined,

    FinanceiroCategoriaDreCategoria: undefined,
    FinanceiroCategoriaDreDemonstrativoResultado: undefined,
    FinanceiroCategoriaDreRegraEscolhaCategoria: undefined,
    FinanceiroGeralBandeiraCartao: undefined,
    FinanceiroGeralFormaPagamento: undefined,
    FinanceiroGeralImpressaoDeRecibo: undefined,
    FinanceiroGeralPortador: undefined,
    FinanceiroGeralRegraCalculoCartao: undefined,
    FinanceiroGeralRegraEscolhaIntegracaoPagamento: undefined,
    FinanceiroGeralRegraEscolhaPortador: undefined,
    FinanceiroMovimentacoesContasReceberPagar: undefined,
    FinanceiroMovimentacoesGeracaoEnvioBoletos: undefined,
    FinanceiroMovimentacoesReversaoContas: undefined,
    FinanceiroAdiantamentoPrevisao: undefined,
    FinanceiroMovimentacoesTransferenciaEntrePortadores: undefined,
    FinanceiroRelatoriosMovimentacoesContasReceberPagar: undefined,
    FinanceiroRelatoriosMovimentacoesMovimentacoesFinanceirasPortadores:
      undefined,

    FiscalDocumentosEletronicosGeracaoDeArquivosContabeis: undefined,
    FiscalDocumentosEletronicosImportacaoXmlEmitidos: undefined,
    FiscalDocumentosEletronicosInutilizacaoNumeracao: undefined,
    FiscalDocumentosEletronicosManutencaoDocumentosFiscais: undefined,
    FiscalSpedConfiguracao: undefined,
    FiscalTributacaoConsultaAliquotaIcmsInternaInterestadual: undefined,
    FiscalTributacaoConsultaAliquotaIpiPorNcm: undefined,
    FiscalTributacaoRegraEscolhaAliquota: undefined,
    FiscalTributacaoRegraEscolhaCst: undefined,
    FiscalTributacaoRegraEscolhaCstsCreditosIcms: undefined,
    FiscalTributacaoRegraEscolhaCstsCreditosIpi: undefined,
    FiscalTributacaoRegraEscolhaCstsCreditosPisCofins: undefined,
    FiscalTributacaoRegraEscolhaOperacaoFiscal: undefined,
    FiscalTributacaoRegraEscolhaParametro: undefined,
    FiscalTributacaoSerie: undefined,
    FiscalTributacaoTipoNota: undefined,

    FinanceiroMovimentacoesControleDeCheques: undefined,

    ManufaturaConfiguradorCaracteristica: undefined,
    ManufaturaConfiguradorConfigCaracteristicasProduto: undefined,
    ManufaturaConfiguradorConjuntoVariaveis: undefined,
    ManufaturaEngenhariaCentroTrabalho: undefined,
    ManufaturaEngenhariaEstruturaProduto: undefined,
    ManufaturaEngenhariaOperacoesRoteirosFabricacao: undefined,
    ManufaturaEngenhariaRoteiroDeFabricacao: undefined,
    ManufaturaEngenhariaRoteiroDeFabricacaoPadrao: undefined,
    ManufaturaProducaoOrdemDesmontagem: undefined,
    ManufaturaProducaoOrdemProducao: undefined,
    ManufaturaProducaoReprocessamentoProducao: undefined,
    ManufaturaRelatoriosEngenhariaEstruturaProduto: undefined,

    ServicoOrdensServicosMarcaVeiculo: undefined,
    ServicoOrdensServicosAcessorio: undefined,
    ServicoOrdensServicosSituacao: undefined,
    ServicoOrdensServicosEquipamento: undefined,
    ServicoOrdensServicosOrdemServico: undefined,

    SuprimentosComprasCotacaoCompra: undefined,
    SuprimentosComprasNotaFiscalEntrada: undefined,
    SuprimentosComprasPedidoCompra: undefined,
    SuprimentosCustoConfiguracao: undefined,
    SuprimentosNotaFiscalConhecimentoConhecimentoTransporteEntradaTerceiro:
      undefined,
    SuprimentosNotaFiscalConhecimentoImportacaoXmlTerceiro: undefined,
    SuprimentosPesagensItensAvaliacaoPesagem: undefined,
    SuprimentosPesagensPesagem: undefined,
    SuprimentosProdutosAtualizacaoEstoqueProduto: undefined,
    SuprimentosProdutosAtualizacaoPrecoCustoProduto: undefined,
    SuprimentosProdutosColecao: undefined,
    SuprimentosProdutosEstoqueLocalEstoque: undefined,
    SuprimentosProdutosEstoqueLote: undefined,
    SuprimentosProdutosEstoqueMovimentacaoEntreProdutos: undefined,
    SuprimentosProdutosEstoqueMovimentoManualEstoque: undefined,
    SuprimentosProdutosEstoqueTransferenciaLocalEstoque: undefined,
    SuprimentosProdutosFamilia: undefined,
    SuprimentosProdutosGrupoProdutoServico: undefined,
    SuprimentosProdutosImportacaoExportacaoCsvProdutos: undefined,
    SuprimentosProdutosEstoqueImportacaoExportacaoCsvEstoques: undefined,
    SuprimentosProdutosImpressaoEtiquetasProdutos: undefined,
    SuprimentosProdutosMarcaProduto: undefined,
    SuprimentosProdutosPadronizacaoDescricao: undefined,
    SuprimentosProdutosProduto: undefined,
    SuprimentosProdutosUnidadeMedida: undefined,
    SuprimentosRelatoriosComprasCotacaoCompra: undefined,
    SuprimentosRelatoriosComprasNotaFiscalEntrada: undefined,
    SuprimentosRelatoriosComprasPedidoCompra: undefined,
    SuprimentosRelatoriosConhecimentoTransporteTerceiro: undefined,
    SuprimentosRelatoriosEstoques: undefined,
    SuprimentosRelatoriosPesagem: undefined,
    SuprimentosRelatoriosProdutos: undefined,
    SuprimentosSisdevinCodigoRastreabilidade: undefined,
    SuprimentosSisdevinConfiguracaoCrEnologicoCultivar: undefined,
    SuprimentosSisdevinGrupoCodigoRastreabilidade: undefined,
    SuprimentosSisdevinMovimentoManualPerdasSisdevin: undefined,
    SuprimentosSisdevinRegraEscolhaParametrosSisdevin: undefined,
    SuprimentosSisdevinManutencaoMovimentosSisdevin: undefined,

    TransporteConhecimentoTransporte: undefined,
    TransporteManifestoTransporte: undefined,
    TransporteRelatoriosConhecimentoTransporte: undefined,
    TransporteRelatoriosManifestoTransporte: undefined,
    TransporteVeiculo: undefined,
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function buscarPermissoes(): Promise<void> {
      setLoading(true);

      if (usuario) {
        try {
          const [responseTipo, responseObterPermissoes] = await Promise.all([
            UsuarioTipoComunicador.index(),
            UsuarioPermissoesComunicador.index(),
          ]);

          setTipoUsuario(responseTipo.tipoUsuario);
          setPermissoes(responseObterPermissoes);
        } catch (error) {
          TratarErros(error);
        }
      }

      setLoading(false);
    }
    buscarPermissoes();
  }, [usuario]);

  if (loading) return <Carregando />;

  return (
    <PermissoesContext.Provider value={{ tipoUsuario, permissoes }}>
      {children}
    </PermissoesContext.Provider>
  );
};

function UsePermissoes(): IPermissoesContextData {
  const context = useContext(PermissoesContext);

  if (!context) {
    throw new Error('UsePermissoes must be used within an PermissoesProvider');
  }

  return context;
}

export { PermissoesProvider, UsePermissoes };
