import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';
import { Dropdown } from 'react-bootstrap';
import { v4 } from 'uuid';
import { BiDetail } from 'react-icons/bi/index.mjs';
import {
  AmbienteEnum,
  EnumObterValue,
  IFrenteCaixaPdvFinalizarVendaValoresInserir,
  SituacaoNotaFiscalSaidaEnum,
  TipoFinalizarEnum,
} from '@elogestor/util';
import * as Yup from 'yup';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import TextoLoading from '../../../../../../Componentes/TextoLoading';
import IPermissao from '../../../../../../Hooks/Interfaces/IPermissao';
import { UseReactSizeMeBodyHook } from '../../../../../../Hooks/ReactSizeMeBodyHook';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import SplitSalvar from '../../../../../../Componentes/SubHeaderGeral/SplitSalvar';
import FinalizarVenda from './FinalizarVenda';
import { ParcelasHook } from './FinalizarVenda/Hooks/ParcelasHook';
import FrenteCaixaPdvGerarNotaFiscalSaidaComunicador from '../../../../../../Comunicador/Comercial/Vendas/FrenteCaixaPdv/Comunicador/FrenteCaixaPdvGerarNotaFiscalSaidaComunicador';
import GetValidationErrors from '../../../../../../Util/Erro/GetValidationErrors';
import { UseFrenteCaixaPdv } from '../Hooks/FrenteCaixaPdvHook';
import NotaFiscalSaidaTransmitirComunicador from '../../../../../../Comunicador/Comercial/Vendas/NotaFiscalSaida/Comunicador/NotaFiscalSaidaTransmitirComunicador';
import ToastErro from '../../../../../../Util/Toasts/ToastErro';
import ToastSucesso from '../../../../../../Util/Toasts/ToastSucesso';
import { UseConfirmacao } from '../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import { UseFrenteCaixaPdvCalculo } from '../Hooks/FrenteCaixaPdvCalculoHook';
import NotaFiscalConsumidorImpressaoComunicador from '../../../../../../Comunicador/Comercial/Vendas/NotaFiscalSaida/Impressao/NotaFiscalConsumidorImpressao/NotaFiscalConsumidorImpressaoComunicador';
import ImpressaoNotaFiscalConsumidor from '../../../../../DocumentosEletronicos/NFCe/Impressao';
import ImpressaoNotaFiscalComprovanteTef from '../../../../../DocumentosEletronicos/NFCe/ImpressaoComprovanteTef';
import { UseParametros } from '../../../../../../Hooks/ParametrosHook';
import NotaFiscalSaidaUploadPdfComunicador from '../../../../../../Comunicador/Comercial/Vendas/NotaFiscalSaida/Comunicador/NotaFiscalSaidaUploadPdfComunicador';
import ImprimirDiretoPDF from '../../../../../../Util/Relatorios/ImprimirDiretoPDF';
import FrenteCaixaPdvGerarPedidoVendaComunicador from '../../../../../../Comunicador/Comercial/Vendas/FrenteCaixaPdv/Comunicador/FrenteCaixaPdvGerarPedidoVendaComunicador';
import FrenteCaixaPdvGerarNotaFiscalSaidaImportacaoPreVendaComunicador from '../../../../../../Comunicador/Comercial/Vendas/FrenteCaixaPdv/Comunicador/FrenteCaixaPdvGerarNotaFiscalSaidaImportacaoPreVendaComunicador';
import TratarValidadeCertificado from '../../../../../../Util/Configuracao/Certificado/TratarValidadeCertificado';
import GerarPreVendaDetalhe from './GerarPreVenda/Detalhe';
import GerarRetiradaConsignadoDetalhe from './GerarRetiradaConsignado/Detalhe';
import FrenteCaixaPdvObterNotaFiscalSaidaComunicador from '../../../../../../Comunicador/Comercial/Vendas/FrenteCaixaPdv/Comunicador/FrenteCaixaPdvObterNotaFiscalSaidaComunicador';
import { Sleep } from '../../../../../../Componentes/Padrao/MenuPrincipal/Scripts';
import FrenteCaixaPdvGerarNotaFiscalSaidaImportacaoRetiradaConsignadoComunicador from '../../../../../../Comunicador/Comercial/Vendas/FrenteCaixaPdv/Comunicador/FrenteCaixaPdvGerarNotaFiscalSaidaImportacaoRetiradaConsignadoComunicador';
import FrenteCaixaPdvRetornoConsignadoComunicador from '../../../../../../Comunicador/Comercial/Vendas/FrenteCaixaPdv/Comunicador/FrenteCaixaPdvRetornoConsignadoComunicador';
import FrenteCaixaPdvPossuiPagamentoTefPendenteComunicador from '../../../../../../Comunicador/Comercial/Vendas/FrenteCaixaPdv/Comunicador/FrenteCaixaPdvPossuiPagamentoTefPendenteComunicador';
import { UseLiberacoes } from '../../../../../../Hooks/LiberacoesHook';
import GerarOrcamentoDetalhe from './GerarOrcamento/Detalhe';
import GerarNotaFiscalSaidaDetalhe, {
  IDadosPadraoNotaFiscalSaida,
} from './GerarNotaFiscalSaida/Detalhe';
import ConfirmacaoValores from '../../../../Pessoas/PessoaValoresEmAberto/ConfirmacaoValores';
import RepresentantesDetalhe from '../AcoesFrenteCaixaPdv/Representantes/Detalhe';

export interface IAcoesFinalizarVendaRef {
  handleFinalizarVenda(): Promise<void>;
}

interface IAcoesFinalizarVenda {
  permissao?: IPermissao;
}

const AcoesFinalizarVenda: React.ForwardRefRenderFunction<
  IAcoesFinalizarVendaRef,
  IAcoesFinalizarVenda
> = ({ permissao }, ref) => {
  const parametros = UseParametros();
  const liberacoes = UseLiberacoes();
  const { telaGrande } = UseReactSizeMeBodyHook();
  const { formRef, loading, setLoading, refresh } = UseForm();
  const {
    listaItem,
    idNotaFiscalSaida,
    setIdNotaFiscalSaida,
    idPreVenda,
    listaIdItemConsignado,
  } = UseFrenteCaixaPdv();
  const { abrirJanela } = UseConfirmacao();
  const { liberar } = UseFrenteCaixaPdvCalculo();

  const situacao = formRef.current?.getFieldValue('situacao');
  const idCliente = formRef.current?.getFieldValue('idCliente');

  const [loadingFinalizar, setLoadingFinalizar] = useState(false);

  const [showFinalizarVenda, setShowFinalizarVenda] = useState(false);
  const [showGerarNotaFiscalSaida, setShowGerarNotaFiscalSaida] =
    useState(false);
  const [showGerarPreVenda, setShowGerarPreVenda] = useState(false);
  const [showGerarRetiradaConsignado, setShowGerarRetiradaConsignado] =
    useState(false);
  const [showGerarOrcamento, setShowGerarOrcamento] = useState(false);
  const [showNotaFiscalSaidaPadrao, setShowNotaFiscalSaidaPadrao] =
    useState(false);
  const [
    dadosPadraoNotaFiscalSaidaPadrao,
    setDadosPadraoNotaFiscalSaidaPadrao,
  ] = useState<IDadosPadraoNotaFiscalSaida>();

  const [showConfirmacaoValores, setShowConfirmacaoValores] = useState(<></>);
  const [showRepresentantes, setShowRepresentantes] = useState(false);

  const [tipoFinalizar, setTipoFinalizar] = useState<TipoFinalizarEnum>(null);

  const handleValidar = useCallback(async (): Promise<boolean> => {
    try {
      formRef.current?.setErrors({});
      const data = formRef.current?.getData() as any;

      const schema = Yup.object().shape({
        valorTotalGeral: Yup.mixed()
          .nullable()
          .test({
            message: 'Valor Total deve se maior que Zero!',
            test: (value: any) => {
              if (!value || value === 0) {
                return false;
              }
              return true;
            },
          }),
      });

      await schema.validate(data, { abortEarly: false });

      const possuiItemSemValor = listaItem.some(
        (item) => item.valorTotalItem === 0
      );

      if (possuiItemSemValor) {
        const resposta = await abrirJanela({
          titulo: <h2>Confirmação</h2>,
          mensagem: (
            <span style={{ fontSize: 20 }}>
              Existem itens sem valor informado. Deseja continuar?
            </span>
          ),
          confimar: 'Sim',
          cancelar: 'Näo',
        });

        return resposta;
      }

      return true;
    } catch (error) {
      const errors = GetValidationErrors(error as any);
      formRef.current?.setErrors(errors);
      return false;
    }
  }, [abrirJanela, formRef, listaItem]);

  const handleValidarGerar = useCallback(async (): Promise<boolean> => {
    try {
      formRef.current?.setErrors({});
      const data = formRef.current?.getData() as any;

      const schema = Yup.object().shape({
        idCliente: Yup.mixed()
          .nullable()
          .test({
            message: 'Cliente é obrigatório!',
            test: (value: any) => {
              if (!value) {
                return false;
              }
              return true;
            },
          }),
      });

      await schema.validate(data, { abortEarly: false });
      return true;
    } catch (error) {
      const errors = GetValidationErrors(error as any);
      formRef.current?.setErrors(errors);
      return false;
    }
  }, [formRef]);

  const handleVisualizarObterBlobPdf = useCallback(
    async (
      visualizarPdf: Boolean,
      idNotaFiscalSaidaTransmitir: string
    ): Promise<{
      blobPdf: Blob | undefined;
      pdfSalvo: boolean;
    }> => {
      let blobPdf: Blob | undefined;
      let pdfSalvo = true;

      const response = await NotaFiscalConsumidorImpressaoComunicador.show({
        id: idNotaFiscalSaidaTransmitir,
      });

      if (response.pdf) {
        if (visualizarPdf) {
          await ImprimirDiretoPDF({ dados: response.pdf });
        }

        blobPdf = response.pdf;
        pdfSalvo = true;
      } else {
        if (visualizarPdf) {
          await ImpressaoNotaFiscalConsumidor.handleImprimirDireto(
            response.impressao
          );
        }

        blobPdf = await ImpressaoNotaFiscalConsumidor.handleObterBlobPdf(
          response.impressao
        );
        pdfSalvo = false;
      }

      if (
        response.listaMovimentoPortadorTef &&
        response.listaMovimentoPortadorTef.length > 0
      ) {
        for (let i = 0; i < response.listaMovimentoPortadorTef.length; i++) {
          const movimentoPortadorTef = response.listaMovimentoPortadorTef[i];

          if (movimentoPortadorTef.comprovanteCliente) {
            await ImpressaoNotaFiscalComprovanteTef.handleVisualizarPdf(
              movimentoPortadorTef.comprovanteCliente
            );
          }

          if (movimentoPortadorTef.comprovanteEstabelecimento) {
            await ImpressaoNotaFiscalComprovanteTef.handleVisualizarPdf(
              movimentoPortadorTef.comprovanteEstabelecimento
            );
          }

          if (movimentoPortadorTef.comprovanteClienteCancelamento) {
            await ImpressaoNotaFiscalComprovanteTef.handleVisualizarPdf(
              movimentoPortadorTef.comprovanteClienteCancelamento
            );
          }

          if (movimentoPortadorTef.comprovanteEstabelecimentoCancelamento) {
            await ImpressaoNotaFiscalComprovanteTef.handleVisualizarPdf(
              movimentoPortadorTef.comprovanteEstabelecimentoCancelamento
            );
          }
        }
      }

      return {
        blobPdf,
        pdfSalvo,
      };
    },
    []
  );

  const handleValidarEstoque = useCallback(async (): Promise<boolean> => {
    const listaErroItem: string[] = [];

    listaItem.forEach((item) => {
      if (
        item.localEstoque &&
        item.localEstoque?.disponivelParaVenda === false
      ) {
        listaErroItem.push(
          `${item.produto.codigo} - ${item.produto.descricao}`
        );
      }
    });

    if (listaErroItem.length > 0) {
      await abrirJanela({
        titulo: (
          <p>
            Não é possível prosseguir. Os seguintes produtos possuem local de
            estoque indisponível para venda:
          </p>
        ),
        mensagem: (
          <span>
            {listaErroItem.map((item) => (
              <p key={item}>{item}</p>
            ))}
          </span>
        ),
        confimar: 'OK',
        padraoSelecionadoSim: true,
      });
      return false;
    }

    return true;
  }, [abrirJanela, listaItem]);

  const handleImprimir = useCallback(
    async (idNotaFiscalSaidaTransmitir: string) => {
      try {
        setLoading(true);

        const dadosPdf = await handleVisualizarObterBlobPdf(
          true,
          idNotaFiscalSaidaTransmitir
        );
        if (dadosPdf.blobPdf && !dadosPdf.pdfSalvo) {
          const chaveAcesso = formRef.current?.getFieldValue('chaveAcesso');
          const ambiente = parametros.AmbienteTransmissaoNotaFiscalSaida;

          const ambienteFormatado = EnumObterValue(AmbienteEnum, ambiente);
          const situacaoFormatado = EnumObterValue(
            SituacaoNotaFiscalSaidaEnum,
            situacao
          );

          const formData = new FormData();
          formData.append(
            'bloblPdf',
            dadosPdf.blobPdf as any,
            `${chaveAcesso}#ambiente:${ambienteFormatado}#situacao:${situacaoFormatado}`
          );

          await NotaFiscalSaidaUploadPdfComunicador.update({
            params: formData,
          });
        }

        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    },
    [formRef, handleVisualizarObterBlobPdf, parametros, setLoading, situacao]
  );

  const handleTransmitirNotaFiscalSaida = useCallback(
    async (idNotaFiscalSaidaTransmitir?: string) => {
      if (!idNotaFiscalSaidaTransmitir && idNotaFiscalSaida) {
        idNotaFiscalSaidaTransmitir = idNotaFiscalSaida;
      }

      if (idNotaFiscalSaidaTransmitir) {
        try {
          await TratarValidadeCertificado();

          const responseTransmitir =
            await NotaFiscalSaidaTransmitirComunicador.update({
              id: idNotaFiscalSaidaTransmitir,
            });

          if (responseTransmitir.mensagemErro) {
            ToastErro(responseTransmitir.mensagemErro, { autoClose: 4000 });
          } else {
            ToastSucesso(responseTransmitir.mensagem);
            await handleImprimir(idNotaFiscalSaidaTransmitir);
          }
        } catch (error) {
          TratarErros(error);
        }
      }
    },
    [handleImprimir, idNotaFiscalSaida]
  );

  const handleConfirmacaoValores = useCallback(async (): Promise<boolean> => {
    return new Promise((resolve) => {
      setShowConfirmacaoValores(
        <ConfirmacaoValores
          idCliente={idCliente}
          loading={loading}
          setLoading={setLoading}
          onResponse={(resposta: boolean) => resolve(resposta)}
        />
      );
    });
  }, [idCliente, loading, setLoading]);

  // #region Finalizar Venda

  const handleFinalizarVenda = useCallback(async () => {
    try {
      const validarEstoque = await handleValidarEstoque();
      if (validarEstoque === false) {
        setLoading(false);
        return;
      }

      setLoading(true);

      if (!liberacoes.permiteGerarCupomFrenteCaixa) {
        await abrirJanela({
          titulo: <h2>Atenção</h2>,
          mensagem: (
            <span style={{ fontSize: 20 }}>
              Usuario não possui liberação para gerar Cupom Fiscal!
            </span>
          ),
        });

        setLoading(false);
        return;
      }

      if (!(await handleValidar())) {
        setLoading(false);
        return;
      }

      const financeiroConferido = formRef.current?.getFieldValue(
        'financeiroConferido'
      );

      let possuiPagamentoTefPendente = false;
      if (idNotaFiscalSaida) {
        const response =
          await FrenteCaixaPdvPossuiPagamentoTefPendenteComunicador.show({
            id: idNotaFiscalSaida,
          });

        possuiPagamentoTefPendente = response;
      }

      if (
        idNotaFiscalSaida &&
        financeiroConferido &&
        !possuiPagamentoTefPendente
      ) {
        const resposta = await abrirJanela({
          titulo: <h2>Confirmação</h2>,
          mensagem: (
            <span style={{ fontSize: 20 }}>
              A venda já foi finalizada, mas não transmitida. Deseja transmitir
              a venda?
            </span>
          ),
        });

        if (resposta) {
          await handleTransmitirNotaFiscalSaida();
          await liberar();
        }

        setLoading(false);
        return;
      }

      if (
        parametros.ExibirMensagemDeContasEConsignadosEmAberto &&
        idCliente !== null &&
        idCliente !== parametros.ClienteConsumidorFinalFrenteCaixaPdv?.id
      ) {
        const respostaConfirmacao = await handleConfirmacaoValores();
        setShowConfirmacaoValores(<></>);

        if (respostaConfirmacao) setShowFinalizarVenda(true);
      } else setShowFinalizarVenda(true);

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    handleValidarEstoque,
    setLoading,
    liberacoes.permiteGerarCupomFrenteCaixa,
    handleValidar,
    formRef,
    idNotaFiscalSaida,
    parametros.ExibirMensagemDeContasEConsignadosEmAberto,
    parametros.ClienteConsumidorFinalFrenteCaixaPdv,
    idCliente,
    abrirJanela,
    handleTransmitirNotaFiscalSaida,
    liberar,
    handleConfirmacaoValores,
  ]);

  const handleFinalizarVendaFinalizarFormModal = useCallback(
    async (
      dados: IFrenteCaixaPdvFinalizarVendaValoresInserir,
      idNotaFiscal?: string | null
    ) => {
      let idNotaFiscalSaidaTransmitir: string | undefined;

      try {
        setLoading(true);
        setLoadingFinalizar(true);

        const data = formRef.current?.getData() as any;

        dados = {
          ...dados,
          ...data,
          listaItem,

          gerarNotaFiscalSaida: false,
        };

        let response: any;
        if (idPreVenda && !idNotaFiscal && !idNotaFiscalSaida) {
          dados.idPedidoVenda = idPreVenda;

          response =
            await FrenteCaixaPdvGerarNotaFiscalSaidaImportacaoPreVendaComunicador.store(
              { params: dados }
            );
        } else if (
          listaIdItemConsignado &&
          !idNotaFiscal &&
          !idNotaFiscalSaida
        ) {
          response =
            await FrenteCaixaPdvGerarNotaFiscalSaidaImportacaoRetiradaConsignadoComunicador.store(
              { params: dados }
            );
        } else {
          if (idNotaFiscal) {
            dados.idNotaFiscalSaida = idNotaFiscal;
          } else if (idNotaFiscalSaida) {
            dados.idNotaFiscalSaida = idNotaFiscalSaida;
          }

          response = await FrenteCaixaPdvGerarNotaFiscalSaidaComunicador.store({
            params: dados,
          });
        }

        if (!idNotaFiscalSaida) {
          setIdNotaFiscalSaida(response.id);
          idNotaFiscalSaidaTransmitir = response.id;
        } else {
          setIdNotaFiscalSaida(idNotaFiscalSaida);
          idNotaFiscalSaidaTransmitir = idNotaFiscalSaida;
        }

        // await handleCarregarDados();

        setLoadingFinalizar(false);
        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
        setLoadingFinalizar(false);
        return;
      }

      try {
        setLoading(true);
        setLoadingFinalizar(true);

        if (idNotaFiscalSaidaTransmitir) {
          const response =
            await FrenteCaixaPdvObterNotaFiscalSaidaComunicador.show({
              id: idNotaFiscalSaidaTransmitir,
            });

          if (response.financeiroConferido) {
            await handleTransmitirNotaFiscalSaida(idNotaFiscalSaidaTransmitir);
          }
        }

        setLoadingFinalizar(false);
        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
        setLoadingFinalizar(false);
      } finally {
        setShowFinalizarVenda(false);
        await Sleep(100);
        await liberar();
      }
    },
    [
      formRef,
      handleTransmitirNotaFiscalSaida,
      idNotaFiscalSaida,
      idPreVenda,
      liberar,
      listaIdItemConsignado,
      listaItem,
      setIdNotaFiscalSaida,
      setLoading,
    ]
  );

  const handleFinalizarVendaFecharFormModal = useCallback(async () => {
    setShowFinalizarVenda(false);
  }, []);

  // #endregion Finalizar Venda

  // #region Gerar Pré Venda

  const handleGerarPreVenda = useCallback(async () => {
    setShowGerarPreVenda(true);
  }, []);

  const handleGerarPreVendaSalvarFormModal = useCallback(async () => {
    setShowGerarPreVenda(false);
  }, []);

  const handleGerarPreVendaFecharFormModal = useCallback(async () => {
    setShowGerarPreVenda(false);
  }, []);

  // #endregion Gerar Pré Venda

  // #region Gerar Pedido Venda

  const handleGerarPedido = useCallback(async () => {
    try {
      setLoading(true);

      if (!(await handleValidarGerar())) {
        setLoading(false);
        return;
      }
      if (!(await handleValidar())) {
        setLoading(false);
        return;
      }

      const data = formRef.current?.getData() as any;

      const dados = {
        ...data,
        listaItem,
        gerarPedidoVenda: true,
      };

      const response = await FrenteCaixaPdvGerarPedidoVendaComunicador.store({
        params: dados,
      });

      if (response) {
        window.open(`/comercial/vendas/pedido-venda/detalhe/${response.id}`);

        ToastSucesso(response.mensagem);
      }

      await liberar();
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    formRef,
    handleValidar,
    handleValidarGerar,
    liberar,
    listaItem,
    setLoading,
  ]);

  // #endregion Gerar Pedido Venda

  // #region Gerar Nota Fiscal Saida

  const handleGerarNotaFiscalSaida = useCallback(async () => {
    const validarEstoque = await handleValidarEstoque();
    if (validarEstoque === false) {
      setLoading(false);
      return;
    }

    if (!(await handleValidarGerar())) {
      return;
    }
    if (!(await handleValidar())) {
      return;
    }

    if (
      parametros.ExibirMensagemDeContasEConsignadosEmAberto &&
      idCliente !== null &&
      idCliente !== parametros.ClienteConsumidorFinalFrenteCaixaPdv?.id
    ) {
      const respostaConfirmacao = await handleConfirmacaoValores();
      setShowConfirmacaoValores(<></>);
      if (respostaConfirmacao) setShowNotaFiscalSaidaPadrao(true);
    } else setShowNotaFiscalSaidaPadrao(true);
  }, [
    handleConfirmacaoValores,
    handleValidar,
    handleValidarEstoque,
    handleValidarGerar,
    idCliente,
    parametros.ClienteConsumidorFinalFrenteCaixaPdv,
    parametros.ExibirMensagemDeContasEConsignadosEmAberto,
    setLoading,
  ]);

  const handleGerarNotaFiscalSaidaFinalizarFormModal = useCallback(
    async (dados: IFrenteCaixaPdvFinalizarVendaValoresInserir) => {
      try {
        setLoading(true);
        setLoadingFinalizar(true);

        const data = formRef.current?.getData() as any;

        dados = {
          ...dados,
          ...data,
          ...dadosPadraoNotaFiscalSaidaPadrao,
          listaItem,

          gerarNotaFiscalSaida: true,
        };

        let response;
        if (idPreVenda && !idNotaFiscalSaida) {
          dados.idPedidoVenda = idPreVenda;

          response =
            await FrenteCaixaPdvGerarNotaFiscalSaidaImportacaoPreVendaComunicador.store(
              { params: dados }
            );
        } else if (listaIdItemConsignado && !idNotaFiscalSaida) {
          response =
            await FrenteCaixaPdvGerarNotaFiscalSaidaImportacaoRetiradaConsignadoComunicador.store(
              { params: dados }
            );
        } else {
          if (idNotaFiscalSaida) {
            dados.idNotaFiscalSaida = idNotaFiscalSaida;
          }

          response = await FrenteCaixaPdvGerarNotaFiscalSaidaComunicador.store({
            params: dados,
          });
        }

        if (response) {
          window.open(
            `/comercial/vendas/nota-fiscal-saida/detalhe/${response.id}`
          );

          ToastSucesso(response.mensagem);
        }

        setShowGerarNotaFiscalSaida(false);
        await liberar();
        setLoadingFinalizar(false);
        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
        setLoadingFinalizar(false);
      }
    },
    [
      dadosPadraoNotaFiscalSaidaPadrao,
      formRef,
      idNotaFiscalSaida,
      idPreVenda,
      liberar,
      listaIdItemConsignado,
      listaItem,
      setLoading,
    ]
  );

  const handleGerarNotaFiscalSaidaFecharFormModal = useCallback(async () => {
    setShowGerarNotaFiscalSaida(false);
  }, []);

  const handleNotaFiscalSaidaPadraoSalvarFormModal = useCallback(
    async (dados: IDadosPadraoNotaFiscalSaida) => {
      setDadosPadraoNotaFiscalSaidaPadrao(dados);
      setShowNotaFiscalSaidaPadrao(false);
      setShowGerarNotaFiscalSaida(true);
    },
    []
  );

  const handleNotaFiscalSaidaPadraoFecharFormModal = useCallback(async () => {
    setShowNotaFiscalSaidaPadrao(false);
  }, []);

  // #endregion Gerar Nota Fiscal Saida

  // #region Gerar Retirada Consignado

  const handleGerarRetiradaConsignado = useCallback(async () => {
    if (!(await handleValidarGerar())) {
      setLoading(false);
      return;
    }
    if (
      parametros.ExibirMensagemDeContasEConsignadosEmAberto &&
      idCliente !== null &&
      idCliente !== parametros.ClienteConsumidorFinalFrenteCaixaPdv?.id
    ) {
      const respostaConfirmacao = await handleConfirmacaoValores();
      setShowConfirmacaoValores(<></>);
      if (respostaConfirmacao) setShowGerarRetiradaConsignado(true);
    } else setShowGerarRetiradaConsignado(true);
  }, [
    handleConfirmacaoValores,
    handleValidarGerar,
    idCliente,
    parametros.ClienteConsumidorFinalFrenteCaixaPdv,
    parametros.ExibirMensagemDeContasEConsignadosEmAberto,
    setLoading,
  ]);

  const handleGerarRetiradaConsignadoSalvarFormModal = useCallback(async () => {
    setShowGerarRetiradaConsignado(false);
    await liberar();
  }, [liberar]);

  const handleGerarOrcamentoSalvarFormModal = useCallback(async () => {
    setShowGerarOrcamento(false);
    await liberar();
  }, [liberar]);

  const handleGerarOrcamentoFecharFormModal = useCallback(async () => {
    setShowGerarOrcamento(false);
  }, []);

  const handleGerarRetiradaConsignadoFecharFormModal = useCallback(async () => {
    setShowGerarRetiradaConsignado(false);
  }, []);

  // #endregion Gerar Retirada Consignado

  const handleRetornoConsignado = useCallback(async () => {
    const resposta = await abrirJanela({
      titulo: <h2>Confirmação</h2>,
      mensagem: (
        <span style={{ fontSize: 20 }}>
          Deseja realmente realizar o Retorno (Devolução) de todos esses itens
          do Consignado?
        </span>
      ),
      padraoSelecionadoSim: false,
      confimar: 'Sim',
      cancelar: 'Não',
    });

    if (!resposta) {
      setLoading(false);
      return;
    }

    try {
      if (!liberacoes.permiteDevolucaoConsignado) {
        ToastErro(
          'Usuário sem permissão para realizar devolução de Consignado!'
        );

        return;
      }

      setLoading(true);

      if (!(await handleValidarGerar())) {
        setLoading(false);
        return;
      }

      const data = formRef.current?.getData() as any;

      const dados = {
        ...data,
        listaItem,
      };

      const response = await FrenteCaixaPdvRetornoConsignadoComunicador.update({
        params: dados,
      });

      if (response) {
        ToastSucesso(response.mensagem);
      }

      await liberar();

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    abrirJanela,
    formRef,
    handleValidarGerar,
    liberacoes.permiteDevolucaoConsignado,
    liberar,
    listaItem,
    setLoading,
  ]);

  useImperativeHandle(ref, () => {
    return {
      handleFinalizarVenda,
    };
  });

  // #region Representantes

  const handleValidarRepresentantes = useCallback(
    (obrigaRepresentante: boolean, tipo: TipoFinalizarEnum): boolean => {
      const listaRepresentantes =
        formRef.current?.getFieldValue('listaRepresentante');

      if (
        !obrigaRepresentante ||
        (obrigaRepresentante &&
          listaRepresentantes &&
          listaRepresentantes.length > 0)
      ) {
        return true;
      }

      formRef.current?.setFieldValue('obrigarRepresentante', true);
      setTipoFinalizar(tipo);
      refresh();

      setShowRepresentantes(true);
      return false;
    },
    [formRef, refresh]
  );

  const handleRepresentantesSalvarFormModal = useCallback(async () => {
    setShowRepresentantes(false);

    const listaRepresentantes =
      formRef.current?.getFieldValue('listaRepresentante');

    if (!listaRepresentantes || listaRepresentantes.length === 0) {
      return;
    }

    if (tipoFinalizar === TipoFinalizarEnum.finalizarVenda) {
      handleFinalizarVenda();
    } else if (tipoFinalizar === TipoFinalizarEnum.gerarPreVenda) {
      handleGerarPreVenda();
    } else if (tipoFinalizar === TipoFinalizarEnum.gerarOrcamento) {
      setShowGerarOrcamento(true);
    } else if (tipoFinalizar === TipoFinalizarEnum.gerarPedido) {
      handleGerarPedido();
    } else if (tipoFinalizar === TipoFinalizarEnum.gerarNotaFiscalSaida) {
      handleGerarNotaFiscalSaida();
    } else if (tipoFinalizar === TipoFinalizarEnum.gerarRetiradaConsignado) {
      handleGerarRetiradaConsignado();
    }

    setTipoFinalizar(null);
    formRef.current?.setFieldValue('obrigarRepresentante', true);
  }, [
    formRef,
    handleFinalizarVenda,
    handleGerarNotaFiscalSaida,
    handleGerarPedido,
    handleGerarPreVenda,
    handleGerarRetiradaConsignado,
    tipoFinalizar,
  ]);

  const handleRepresentantesFecharFormModal = useCallback(async () => {
    setShowRepresentantes(false);
    setTipoFinalizar(null);

    formRef.current?.setFieldValue('obrigarRepresentante', true);
  }, [formRef]);

  // #endregion Representantes

  return (
    <>
      <SplitSalvar
        id={v4()}
        className="btn-group"
        style={{ width: 190, minWidth: 50 }}
        title={
          <div>
            <TextoLoading loading={loading}>
              <BiDetail />
              {telaGrande && (
                <span style={{ marginLeft: 10, marginRight: 10 }}>
                  Finalizar (F6)
                </span>
              )}
            </TextoLoading>
          </div>
        }
        onClick={() => {
          const validacao = handleValidarRepresentantes(
            parametros.RepresentanteObrigatorioNotaFiscalSaida,
            TipoFinalizarEnum.finalizarVenda
          );

          if (validacao) {
            handleFinalizarVenda();
          }
        }}
        disabled={
          loading ||
          permissao?.inclui ||
          listaItem.length === 0 ||
          !!(
            idNotaFiscalSaida &&
            (situacao === SituacaoNotaFiscalSaidaEnum.autorizada ||
              situacao === SituacaoNotaFiscalSaidaEnum.cancelada)
          )
        }
      >
        <Dropdown.Item
          disabled={
            loading ||
            !!(
              idNotaFiscalSaida &&
              (situacao === SituacaoNotaFiscalSaidaEnum.autorizada ||
                situacao === SituacaoNotaFiscalSaidaEnum.cancelada)
            )
          }
          onClick={() => {
            const validacao = handleValidarRepresentantes(
              parametros.RepresentanteObrigatorioNotaFiscalSaida,
              TipoFinalizarEnum.finalizarVenda
            );

            if (validacao) {
              handleFinalizarVenda();
            }
          }}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <BiDetail style={{ marginRight: 5 }} />
            Finalizar Venda
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            loading ||
            !!idNotaFiscalSaida ||
            !!idPreVenda ||
            !!listaIdItemConsignado
          }
          onClick={() => {
            const validacao = handleValidarRepresentantes(
              parametros.RepresentanteObrigatorioPedidoVenda,
              TipoFinalizarEnum.gerarPreVenda
            );

            if (validacao) {
              handleGerarPreVenda();
            }
          }}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <BiDetail style={{ marginRight: 5 }} />
            Gerar Pré Venda
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            loading ||
            !!idNotaFiscalSaida ||
            !!idPreVenda ||
            !!listaIdItemConsignado
          }
          onClick={() => {
            const validacao = handleValidarRepresentantes(
              parametros.RepresentanteObrigatorioOrcamento,
              TipoFinalizarEnum.gerarOrcamento
            );

            if (validacao) {
              setShowGerarOrcamento(true);
            }
          }}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <BiDetail style={{ marginRight: 5 }} />
            Gerar Orçamento
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            loading ||
            !!idNotaFiscalSaida ||
            !!idPreVenda ||
            !!listaIdItemConsignado
          }
          onClick={() => {
            const validacao = handleValidarRepresentantes(
              parametros.RepresentanteObrigatorioPedidoVenda,
              TipoFinalizarEnum.gerarPedido
            );

            if (validacao) {
              handleGerarPedido();
            }
          }}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <BiDetail style={{ marginRight: 5 }} />
            Gerar Pedido
          </span>
        </Dropdown.Item>

        {liberacoes.permiteGerarNotaFrenteCaixa && (
          <Dropdown.Item
            disabled={loading || !!idNotaFiscalSaida}
            onClick={() => {
              const validacao = handleValidarRepresentantes(
                parametros.RepresentanteObrigatorioNotaFiscalSaida,
                TipoFinalizarEnum.gerarNotaFiscalSaida
              );

              if (validacao) {
                handleGerarNotaFiscalSaida();
              }
            }}
          >
            <span style={{ display: 'flex', fontSize: 16 }}>
              <BiDetail style={{ marginRight: 5 }} />
              Gerar Nota Fiscal de Saída
            </span>
          </Dropdown.Item>
        )}

        <Dropdown.Item
          disabled={
            loading ||
            !!idNotaFiscalSaida ||
            !!idPreVenda ||
            !!listaIdItemConsignado
          }
          onClick={() => {
            const validacao = handleValidarRepresentantes(
              parametros.RepresentanteObrigatorioPedidoVenda,
              TipoFinalizarEnum.gerarRetiradaConsignado
            );

            if (validacao) {
              handleGerarRetiradaConsignado();
            }
          }}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <BiDetail style={{ marginRight: 5 }} />
            Gerar Retirada em Consignado
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            loading || !!idNotaFiscalSaida || !!idPreVenda || !idCliente
          }
          onClick={handleRetornoConsignado}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <BiDetail style={{ marginRight: 5 }} />
            Retorno do Consignado
          </span>
        </Dropdown.Item>
      </SplitSalvar>

      {showFinalizarVenda && (
        <ParcelasHook>
          <FinalizarVenda
            onFinalizarFormModal={handleFinalizarVendaFinalizarFormModal}
            onFecharFormModal={handleFinalizarVendaFecharFormModal}
            loading={loadingFinalizar}
            setLoading={setLoadingFinalizar}
            gerarNotaFiscalSaida={false}
          />
        </ParcelasHook>
      )}

      {showGerarNotaFiscalSaida && (
        <ParcelasHook>
          <FinalizarVenda
            onFinalizarFormModal={(dados) => {
              handleGerarNotaFiscalSaidaFinalizarFormModal(dados);
            }}
            onFecharFormModal={handleGerarNotaFiscalSaidaFecharFormModal}
            loading={loadingFinalizar}
            setLoading={setLoadingFinalizar}
            gerarNotaFiscalSaida
          />
          
        </ParcelasHook>
      )}

      {showGerarPreVenda && (
        <GerarPreVendaDetalhe
          onSalvarFormModal={handleGerarPreVendaSalvarFormModal}
          onFecharFormModal={handleGerarPreVendaFecharFormModal}
          loading={loading}
          setLoading={setLoading}
        />
      )}

      {showGerarRetiradaConsignado && (
        <GerarRetiradaConsignadoDetalhe
          onSalvarFormModal={handleGerarRetiradaConsignadoSalvarFormModal}
          onFecharFormModal={handleGerarRetiradaConsignadoFecharFormModal}
          loading={loading}
          setLoading={setLoading}
          idCliente={idCliente}
        />
      )}

      {showGerarOrcamento && (
        <GerarOrcamentoDetalhe
          onSalvarFormModal={handleGerarOrcamentoSalvarFormModal}
          onFecharFormModal={handleGerarOrcamentoFecharFormModal}
          loading={loading}
          setLoading={setLoading}
        />
      )}

      {showNotaFiscalSaidaPadrao && (
        <GerarNotaFiscalSaidaDetalhe
          onSalvarFormModal={handleNotaFiscalSaidaPadraoSalvarFormModal}
          onFecharFormModal={handleNotaFiscalSaidaPadraoFecharFormModal}
          loading={loading}
          setLoading={setLoading}
        />
      )}

      {showConfirmacaoValores}

      {showRepresentantes && (
        <RepresentantesDetalhe
          onSalvarFormModal={handleRepresentantesSalvarFormModal}
          onFecharFormModal={handleRepresentantesFecharFormModal}
          loading={loading}
          setLoading={setLoading}
        />
      )}
    </>
  );
};

export default forwardRef(AcoesFinalizarVenda);
