import {
  AppErro,
  FormatarDataHoraParaPtBr,
  FormatarEnum,
  IPedidoVendaImportacaoLogLista,
  IPedidoVendaImportacaoLogValoresSincronizarFront,
  PlataformaIntegracaoEnum,
} from '@elogestor/util';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import { FaSync } from 'react-icons/fa/index.mjs';
import TipoPesquisaIntegracaoEnum from '@elogestor/util/dist/Enum/Comercial/Vendas/PedidoVendaImportacaoLog/TipoPesquisaIntegracaoEnum';
import IPesquisa from '../../../../../Componentes/Lista/Interface/IPesquisa';
import ITh from '../../../../../Componentes/Lista/Interface/ITh';
import PesquisaAvancada from './PesquisaAvancada';
import ListaProvider from '../../../../../Componentes/Lista/ListaProvider';
import { Container } from './styles';
import SubHeader from '../../../../../Componentes/Lista/SubHeader';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import Lista from '../../../../../Componentes/Lista';
import PersonalizacaoListaCamposComunicador from '../../../../../Comunicador/Configuracao/PersonalizacaoListaRotas/Campos/Comunicador/PersonalizacaoListaCamposComunicador';
import PedidoVendaImportacaoLogComunicador from '../../../../../Comunicador/Comercial/Vendas/PedidoVendaImportacaoLog/Comunicador/PedidoVendaImportacaoLogComunicador';
import Divisor from '../../../../../Componentes/Divisor';
import Select from '../../../../../Componentes/Select';
import InputDate from '../../../../../Componentes/Inputs/InputDate';
import TextoLoading from '../../../../../Componentes/TextoLoading';
import Input from '../../../../../Componentes/Inputs/Input';
import PedidoVendaImportacaoLogSincronizacaoComunicador from '../../../../../Comunicador/Comercial/Vendas/PedidoVendaImportacaoLog/Comunicador/PedidoVendaImportacaoLogSincronizacaoComunicador';
import ToastSucesso from '../../../../../Util/Toasts/ToastSucesso';
import ToastErro from '../../../../../Util/Toasts/ToastErro';

const PedidoVendaImportacaoLogLista: React.FC = () => {
  const { permissoes } = UsePermissoes();
  const formRef = useRef<IFormCiaHandles>(null);

  const [loading, setLoading] = useState(false);

  const [, setRefresh] = useState(0);
  const [refreshPagina, setRefreshPagina] = useState(false);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const [tipoPesquisaIntegracaoEnum, setTipoPesquisaIntegracaoEnum] = useState({
    data: TipoPesquisaIntegracaoEnum.data,
    pedidoEspecifico: TipoPesquisaIntegracaoEnum.pedidoEspecifico,
  });

  const tipoPesquisaIntegracao = formRef.current?.getFieldValue(
    'tipoPesquisaIntegracao'
  );

  const formato = formRef.current?.getFieldValue('formato');

  const handleValidarDadosSincronizacao = useCallback(async (dados: any) => {
    if (
      dados.tipoPesquisaIntegracao === TipoPesquisaIntegracaoEnum.data &&
      (dados.data === '' || dados.data === null || dados.data === undefined)
    ) {
      ToastErro('O campo "Data" é obrigatório');
      return false;
    }
    if (
      dados.tipoPesquisaIntegracao ===
        TipoPesquisaIntegracaoEnum.pedidoEspecifico &&
      (dados.idPedidoEspecifico === '' ||
        dados.idPedidoEspecifico === null ||
        dados.idPedidoEspecifico === undefined)
    ) {
      ToastErro('O campo "Pedido Específico" é obrigatório');
      return false;
    }

    return true;
  }, []);

  const handleSincronizar = useCallback(async () => {
    try {
      const dados = formRef.current?.getData();

      if (!dados) {
        ToastErro('Campos Não Preenchidos!');
        return;
      }

      const dadosFormatados: IPedidoVendaImportacaoLogValoresSincronizarFront =
        {
          data: dados.data,
          idPedidoEspecifico: dados.idPedidoEspecifico,
          tipoPesquisaIntegracao: dados.tipoPesquisaIntegracao,
          formato: dados.formato,
        };

      const dadosValidados =
        await handleValidarDadosSincronizacao(dadosFormatados);

      if (!dadosValidados) {
        return;
      }

      setLoading(true);

      const response =
        await PedidoVendaImportacaoLogSincronizacaoComunicador.store({
          params: dadosFormatados,
        });

      if (response) {
        ToastSucesso(`${response.mensagem}`);
      }

      setLoading(false);
      setRefreshPagina((state) => !state);
      refresh();
    } catch (error) {
      setLoading(false);
      TratarErros(error);
    }
  }, [handleValidarDadosSincronizacao, refresh]);

  const handlePesquisarDados = useCallback(
    async ({
      pesquisaAvancada,
      order,
      descAsc,
      limite,
      pagina,
      textoPesquisa,
    }: IPesquisa) => {
      try {
        setLoading(true);

        const response = await PedidoVendaImportacaoLogComunicador.index({
          params: {
            pesquisaAvancada,
            order,
            descAsc,
            limite,
            pagina,
            textoPesquisa,
          },
        });

        const dados = response.dados[0].map(
          (data: IPedidoVendaImportacaoLogLista) => {
            return {
              id: data.id,
              'pedidoVenda.numero': (
                <div className="lista-texto">
                  {data.pedidoVenda ? data.pedidoVenda.numero : ''}
                </div>
              ),
              data: (
                <div className="lista-data">
                  {FormatarDataHoraParaPtBr(data.data)}
                </div>
              ),
              situacao: <div className="lista-texto">{data.situacao}</div>,
              mensagem: <div className="lista-texto">{data.mensagem}</div>,
              numeroPedidoIntegracao: (
                <div className="lista-texto">{data.numeroPedidoIntegracao}</div>
              ),
              plataformaIntegracao: (
                <div className="lista-texto">{data.plataformaIntegracao}</div>
              ),
            };
          }
        );

        setLoading(false);
        return { dados, totalPaginas: response.dados[1] };
      } catch (error) {
        TratarErros(error);
        setLoading(false);
        return { dados: [], totalPaginas: 0 };
      }
    },
    []
  );

  const handlePesquisarListaTh = useCallback(async () => {
    try {
      const response = await PersonalizacaoListaCamposComunicador.index({
        params: {
          nomeTabela: 'comercialPedidoVendaImportacaoLog',
        },
      });

      const listaTh: ITh[] = response.campos;

      return listaTh;
    } catch (error) {
      TratarErros(error);
      return [];
    }
  }, []);

  useEffect(() => {
    const data = formRef.current?.getFieldValue('data');
    if (!data) {
      const dataPesquisa = new Date();
      dataPesquisa.setDate(dataPesquisa.getDate() - 10);

      formRef.current?.setFieldValue('data', dataPesquisa);
    }
  }, [refresh]);

  // FW7 não permite mandar o Pedido Específico como filtro
  useEffect(() => {
    const _formato = formRef.current?.getFieldValue('formato');
    if (_formato === PlataformaIntegracaoEnum.fw7) {
      setTipoPesquisaIntegracaoEnum({
        data: TipoPesquisaIntegracaoEnum.data,
      } as any);
      formRef.current?.setFieldValue('tipoPesquisaIntegracao', 'Data');
    } else {
      setTipoPesquisaIntegracaoEnum({
        data: TipoPesquisaIntegracaoEnum.data,
        pedidoEspecifico: TipoPesquisaIntegracaoEnum.pedidoEspecifico,
      } as any);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formato]);

  return (
    <Container>
      <ListaProvider>
        <SubHeader
          titulo="Logs Integração dos Pedidos"
          permissao={permissoes.ComercialVendasLogsIntegracaoPedidos}
          botaoNovo={false}
        />

        <PesquisaAvancada />

        <Lista
          headerLista={
            <Divisor>
              <FormCia ref={formRef}>
                <Row>
                  <Col xl={3}>
                    <Select
                      name="formato"
                      label="Plataforma"
                      options={FormatarEnum({
                        enumObj: PlataformaIntegracaoEnum,
                      })}
                      onChange={() => {
                        refresh();
                      }}
                    />
                  </Col>
                  <Col xl={3}>
                    <Select
                      name="tipoPesquisaIntegracao"
                      label="Tipo Pesquisa Integração"
                      options={FormatarEnum({
                        enumObj: tipoPesquisaIntegracaoEnum,
                      })}
                      onChange={refresh}
                    />
                  </Col>

                  <Col
                    xl={3}
                    style={{
                      display:
                        tipoPesquisaIntegracao ===
                        tipoPesquisaIntegracaoEnum.data
                          ? 'block'
                          : 'none',
                    }}
                  >
                    <InputDate name="data" label={'Data'} />
                  </Col>

                  <Col
                    xl={3}
                    style={{
                      display:
                        tipoPesquisaIntegracao ===
                        tipoPesquisaIntegracaoEnum.pedidoEspecifico
                          ? 'block'
                          : 'none',
                    }}
                  >
                    <Input
                      name="idPedidoEspecifico"
                      label="Número do Pedido de Integração"
                    />
                  </Col>
                  <Col
                    xl={3}
                    style={{
                      display: 'flex',
                      alignItems: 'end',
                    }}
                  >
                    <button
                      type="button"
                      // onClick={handleConsultarNSUFaltantes}
                      className="btn-padrao btn-azul-escuro"
                      style={{
                        height: '33px',
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: 6,
                        justifyContent: 'center',
                        width: '100%',
                        whiteSpace: 'nowrap',
                      }}
                      onClick={handleSincronizar}
                      disabled={loading}
                    >
                      <TextoLoading loading={loading}>
                        <FaSync size={14} style={{ minWidth: '10%' }} />
                        <span style={{ marginLeft: 5, fontSize: '90%' }}>
                          Sincronizar
                        </span>
                      </TextoLoading>
                    </button>
                  </Col>
                </Row>
              </FormCia>
            </Divisor>
          }
          pesquisarDados={handlePesquisarDados}
          pesquisarListaTh={handlePesquisarListaTh}
          detalhe={false}
          refresh={refreshPagina}
          loading={loading}
        />
      </ListaProvider>
    </Container>
  );
};

export default PedidoVendaImportacaoLogLista;
