import React, { useCallback, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { v4 } from 'uuid';
import { AiOutlineSearch } from 'react-icons/ai/index.mjs';
import { TipoPesquisaFrenteCaixaPdvEnum } from '@elogestor/util';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import TextoLoading from '../../../../../../Componentes/TextoLoading';
import IPermissao from '../../../../../../Hooks/Interfaces/IPermissao';
import { UseReactSizeMeBodyHook } from '../../../../../../Hooks/ReactSizeMeBodyHook';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import PesquisarLista from './PesquisarLista';
import PesquisarListaHook from './PesquisarLista/Hook';
import SplitBotao from '../../../../../../Componentes/SubHeaderGeral/SplitBotao';
import { UseFrenteCaixaPdv } from '../Hooks/FrenteCaixaPdvHook';
import { UseFrenteCaixaPdvCalculo } from '../Hooks/FrenteCaixaPdvCalculoHook';
import FrenteCaixaPdvObterListaIdItemConsignadoComunicador from '../../../../../../Comunicador/Comercial/Vendas/FrenteCaixaPdv/Comunicador/FrenteCaixaPdvObterListaIdItemConsignadoComunicador';

interface IAcoesPesquisarVenda {
  permissao?: IPermissao;
}

const AcoesPesquisarVenda: React.FC<IAcoesPesquisarVenda> = ({ permissao }) => {
  const { telaGrande } = UseReactSizeMeBodyHook();
  const { loading, setLoading, handleCarregarDados } = UseForm();
  const {
    idNotaFiscalSaida,
    setIdNotaFiscalSaida,
    idPreVenda,
    setIdPreVenda,
    listaIdItemConsignado,
    setListaIdItemConsignado,
    listaItem,
  } = UseFrenteCaixaPdv();
  const { liberar } = UseFrenteCaixaPdvCalculo();

  const [showPesquisarVenda, setShowPesquisarVenda] = useState(false);
  const [showPesquisarPreVenda, setShowPesquisarPreVenda] = useState(false);
  const [showPesquisarConsignado, setShowPesquisarConsignado] = useState(false);
  const [showPesquisarValePresente, setShowPesquisarValePresente] =
    useState(false);

  // #region Pesquisar Venda

  const handlePesquisarVenda = useCallback(async () => {
    setShowPesquisarVenda(true);
  }, []);

  const handlePesquisarVendaSelecionarItemFormModal = useCallback(
    async (idItemSelecionado: string) => {
      try {
        setLoading(true);

        await liberar();

        setShowPesquisarVenda(false);
        setIdNotaFiscalSaida(idItemSelecionado);
        await handleCarregarDados();

        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    },
    [handleCarregarDados, liberar, setIdNotaFiscalSaida, setLoading]
  );

  const handlePesquisarVendaFecharFormModal = useCallback(async () => {
    setShowPesquisarVenda(false);
  }, []);

  // #endregion Pesquisar Venda

  // #region Pesquisar Pre Venda

  const handlePesquisarPreVenda = useCallback(async () => {
    setShowPesquisarPreVenda(true);
  }, []);

  const handlePesquisarPreVendaSelecionarItemFormModal = useCallback(
    async (idItemSelecionado: string) => {
      try {
        setLoading(true);

        await liberar();

        setShowPesquisarPreVenda(false);
        setIdPreVenda(idItemSelecionado);
        await handleCarregarDados();

        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    },
    [handleCarregarDados, liberar, setIdPreVenda, setLoading]
  );

  const handlePesquisarPreVendaFecharFormModal = useCallback(async () => {
    setShowPesquisarPreVenda(false);
  }, []);

  // #endregion Pesquisar Pre Venda

  // #region Pesquisar Consignado

  const handlePesquisarConsignado = useCallback(async () => {
    setShowPesquisarConsignado(true);
  }, []);

  const handlePesquisarConsignadoSelecionarItemFormModal = useCallback(
    async (idItemSelecionado: string) => {
      try {
        setLoading(true);

        await liberar();

        setShowPesquisarConsignado(false);

        const response =
          await FrenteCaixaPdvObterListaIdItemConsignadoComunicador.show({
            id: idItemSelecionado,
          });

        setListaIdItemConsignado(response.listaIdItemConsignado);
        await handleCarregarDados();

        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    },
    [handleCarregarDados, liberar, setListaIdItemConsignado, setLoading]
  );

  const handlePesquisarConsignadoFecharFormModal = useCallback(async () => {
    setShowPesquisarConsignado(false);
  }, []);

  // #endregion Pesquisar Consignado

  // #region Pesquisar ValePresente

  const handlePesquisarValePresente = useCallback(async () => {
    setShowPesquisarValePresente(true);
  }, []);

  const handlePesquisarValePresenteSelecionarItemFormModal = useCallback(
    async (idItemSelecionado: string) => {
      try {
        setLoading(true);

        await liberar();

        setShowPesquisarValePresente(false);
        setIdNotaFiscalSaida(idItemSelecionado);
        await handleCarregarDados();

        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    },
    [handleCarregarDados, liberar, setIdNotaFiscalSaida, setLoading]
  );

  const handlePesquisarValePresenteFecharFormModal = useCallback(async () => {
    setShowPesquisarValePresente(false);
  }, []);

  // #endregion Pesquisar ValePresente

  return (
    <>
      <PesquisarListaHook>
        <SplitBotao
          id={v4()}
          className="btn-group"
          style={{ width: 190, minWidth: 50 }}
          title={
            <div>
              <TextoLoading loading={loading}>
                <AiOutlineSearch />
                {telaGrande && (
                  <span style={{ marginLeft: 10, marginRight: 10 }}>
                    Pesquisar
                  </span>
                )}
              </TextoLoading>
            </div>
          }
          onClick={handlePesquisarVenda}
          disabled={
            loading ||
            !!idNotaFiscalSaida ||
            !!idPreVenda ||
            !!listaIdItemConsignado ||
            listaItem.length > 0
          }
        >
          <Dropdown.Item
            disabled={
              loading ||
              !!idNotaFiscalSaida ||
              !!idPreVenda ||
              !!listaIdItemConsignado ||
              listaItem.length > 0
            }
            onClick={handlePesquisarVenda}
          >
            <span style={{ display: 'flex', fontSize: 16 }}>
              <AiOutlineSearch style={{ marginRight: 5 }} />
              Pesquisar Venda
            </span>
          </Dropdown.Item>

          <Dropdown.Item
            disabled={
              loading ||
              !!idNotaFiscalSaida ||
              !!idPreVenda ||
              !!listaIdItemConsignado ||
              listaItem.length > 0
            }
            onClick={handlePesquisarPreVenda}
          >
            <span style={{ display: 'flex', fontSize: 16 }}>
              <AiOutlineSearch style={{ marginRight: 5 }} />
              Pesquisar Pré Venda
            </span>
          </Dropdown.Item>

          <Dropdown.Item
            disabled={
              loading ||
              !!idNotaFiscalSaida ||
              !!idPreVenda ||
              !!listaIdItemConsignado ||
              listaItem.length > 0
            }
            onClick={handlePesquisarConsignado}
          >
            <span style={{ display: 'flex', fontSize: 16 }}>
              <AiOutlineSearch style={{ marginRight: 5 }} />
              Pesquisar Consignado
            </span>
          </Dropdown.Item>

          <Dropdown.Item
            disabled={
              loading ||
              !!idNotaFiscalSaida ||
              !!idPreVenda ||
              !!listaIdItemConsignado ||
              listaItem.length > 0
            }
            onClick={handlePesquisarValePresente}
          >
            <span style={{ display: 'flex', fontSize: 16 }}>
              <AiOutlineSearch style={{ marginRight: 5 }} />
              Pesquisar Vale Presente
            </span>
          </Dropdown.Item>
        </SplitBotao>

        {showPesquisarVenda && (
          <PesquisarLista
            titulo="Pesquisar Venda"
            tipoPesquisa={TipoPesquisaFrenteCaixaPdvEnum.notaFiscalSaida}
            onSelecionarItemFormModal={
              handlePesquisarVendaSelecionarItemFormModal
            }
            onFecharFormModal={handlePesquisarVendaFecharFormModal}
            loading={loading}
            setLoading={setLoading}
          />
        )}

        {showPesquisarPreVenda && (
          <PesquisarLista
            titulo="Pesquisar Pré Venda"
            tipoPesquisa={TipoPesquisaFrenteCaixaPdvEnum.preVenda}
            onSelecionarItemFormModal={
              handlePesquisarPreVendaSelecionarItemFormModal
            }
            onFecharFormModal={handlePesquisarPreVendaFecharFormModal}
            loading={loading}
            setLoading={setLoading}
          />
        )}

        {showPesquisarConsignado && (
          <PesquisarLista
            titulo="Pesquisar Consignados"
            tipoPesquisa={TipoPesquisaFrenteCaixaPdvEnum.consignado}
            onSelecionarItemFormModal={
              handlePesquisarConsignadoSelecionarItemFormModal
            }
            onFecharFormModal={handlePesquisarConsignadoFecharFormModal}
            loading={loading}
            setLoading={setLoading}
          />
        )}

        {showPesquisarValePresente && (
          <PesquisarLista
            titulo="Pesquisar Vale Presente"
            tipoPesquisa={TipoPesquisaFrenteCaixaPdvEnum.valePresente}
            onSelecionarItemFormModal={
              handlePesquisarValePresenteSelecionarItemFormModal
            }
            onFecharFormModal={handlePesquisarValePresenteFecharFormModal}
            loading={loading}
            setLoading={setLoading}
          />
        )}
      </PesquisarListaHook>
    </>
  );
};

export default AcoesPesquisarVenda;
