import React, { useCallback, useEffect } from 'react';
import { Modal, Button, Container, Tabs, Tab } from 'react-bootstrap';
import { FormCia } from '@elogestor/unformcia';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { BiSave } from 'react-icons/bi/index.mjs';
import { v4 } from 'uuid';
import {
  IContaParcelaValoresInserir,
  SituacaoContaEnum,
  TipoAdiantamentoEnum,
} from '@elogestor/util';
import JanelaDetalhe from '../../../../../../../../../../Componentes/JanelaDetalhe';
import LoadingDiv from '../../../../../../../../../../Componentes/LoadingDiv';
import TextoLoading from '../../../../../../../../../../Componentes/TextoLoading';
import { UsePermissoes } from '../../../../../../../../../../Hooks/Permissoes';
import { UseListaDetalheForm } from '../../../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import GeralTab from './GeralTab';
import CategoriaTab from './CategoriaTab';
import { UseForm } from '../../../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseContaListaCategoria } from '../../../../../Hooks/ContaListaCategoriaHook';
import { UseParametros } from '../../../../../../../../../../Hooks/ParametrosHook';

interface IContaParcelaLiquidacaoModal {
  onSalvarFormModal(): void;
  onFecharFormModal(): void;
  onLimparFormModal(): void;
  indexParcela: number;
  indexParcelaLiquidacao?: string;
}

const ContaParcelaLiquidacaoDetalhe: React.FC<IContaParcelaLiquidacaoModal> = ({
  onFecharFormModal,
  onSalvarFormModal,
  indexParcela,
  indexParcelaLiquidacao,
}) => {
  const formPrincipal = UseForm();
  const { permissoes } = UsePermissoes();
  const parametros = UseParametros();
  const isAdiantamentoAntecipacao =
    formPrincipal.formRef.current?.getFieldValue('isAdiantamentoAntecipacao');
  const revertida = formPrincipal.formRef.current?.getFieldValue('revertida');

  const permissao = isAdiantamentoAntecipacao
    ? permissoes.FinanceiroAdiantamentoPrevisao
    : permissoes.FinanceiroMovimentacoesContasReceberPagar;

  const {
    formRefDetalhe,
    formRefLista,
    loading,
    getIdDetalheRegistro,
    handleSubmit,
    handleCarregarDados,
    refresh,
  } = UseListaDetalheForm();
  const {
    categoriaValida,
    validarCategoriasAvista,
    atualizarCategoriasParcelaLiquidacao,
    calcularValorTotalCategoriaParaLiquidacao,
    calcularValorCampoCategoriaParcela,
    calcularRateioCategoriasNaoLiquidada,
  } = UseContaListaCategoria();
  const idDetalheRegistro = getIdDetalheRegistro();

  const handleInserirListaContaParcelaLiquidacaoCategoria =
    useCallback(async () => {
      const listaContaCategoria = formPrincipal.formRef.current?.getFieldValue(
        'listaContaCategoria'
      );

      const listaContaParcela =
        formPrincipal.formRef.current?.getFieldValue('listaContaParcela');

      const listaContaParcelaNova = await atualizarCategoriasParcelaLiquidacao({
        listaContaCategoria,
        listaContaParcela,
      });

      if (!idDetalheRegistro) {
        if (
          listaContaParcelaNova[indexParcela].listaContaParcelaCategoria &&
          listaContaParcelaNova[indexParcela].listaContaParcelaCategoria
            .length > 0
        ) {
          const valoresCategoria = await calcularValorCampoCategoriaParcela(
            listaContaParcelaNova[indexParcela].listaContaParcelaCategoria
          );

          const data = formRefDetalhe.current?.getData();
          const listaMovimentoPortadorCategoria: any[] = [];

          listaContaParcelaNova[indexParcela].listaContaParcelaCategoria.map(
            async (item: any) => {
              const valorCategoria =
                await calcularValorTotalCategoriaParaLiquidacao(
                  item,
                  data,
                  valoresCategoria
                );

              const categoriaFiltrada = listaContaCategoria.find(
                (it: any) => it.idCategoria === item.idCategoria
              );

              if (valorCategoria > 0) {
                listaMovimentoPortadorCategoria.push({
                  ...item,
                  id: undefined,
                  valor: valorCategoria,
                  percentualCategoria:
                    (valorCategoria / categoriaFiltrada.valor) * 100,
                });
              }
            }
          );

          calcularRateioCategoriasNaoLiquidada(
            data,
            listaMovimentoPortadorCategoria
          );

          handleCarregarDados({
            dadosPadrao: { listaMovimentoPortadorCategoria, ...data },
          });

          formRefDetalhe.current?.setFieldValue(
            'listaMovimentoPortadorCategoria',
            listaMovimentoPortadorCategoria
          );
        }
      } else if (
        listaContaParcelaNova[indexParcela].listaContaParcelaLiquidacao[
          Number(idDetalheRegistro)
        ].listaMovimentoPortadorCategoria
      ) {
        formRefDetalhe.current?.setFieldValue(
          'listaMovimentoPortadorCategoria',
          listaContaParcelaNova[indexParcela].listaContaParcelaLiquidacao[
            Number(idDetalheRegistro)
          ].listaMovimentoPortadorCategoria
        );
      }

      await validarCategoriasAvista({
        listaContaCategoria,
        listaContaParcela: listaContaParcelaNova,
      });

      formPrincipal.formRef.current?.setFieldValue(
        'listaContaParcela',
        listaContaParcelaNova
      );

      refresh();
      formPrincipal.refresh();
    }, [
      atualizarCategoriasParcelaLiquidacao,
      calcularRateioCategoriasNaoLiquidada,
      calcularValorCampoCategoriaParcela,
      calcularValorTotalCategoriaParaLiquidacao,
      formPrincipal,
      formRefDetalhe,
      handleCarregarDados,
      idDetalheRegistro,
      indexParcela,
      refresh,
      validarCategoriasAvista,
    ]);

  const handleObterSomaCamposLiquidacao = useCallback(async () => {
    const somaValores = {
      valoresGerais: 0,
      valoresJurosSoma: 0,
      valoresDescontoSubtrai: 0,
      valoresMultaSoma: 0,
      valoresOutrasDespesasSoma: 0,
      valoresOutrasDespesasSubtrai: 0,
      valoresDespesasCartaoSubtrai: 0,
      valoresDespesasCartorioSoma: 0,
      valoresDespesasEnvioSoma: 0,
      valoresComissoesSoma: 0,
      valoresDespesasEnvioSubtrai: 0,
      valoresComissoesSubtrai: 0,
    };

    const listaContaParcelaLiquidacao = formRefLista.current?.getFieldValue(
      'listaContaParcelaLiquidacao'
    );

    if (listaContaParcelaLiquidacao) {
      listaContaParcelaLiquidacao.forEach((item: any) => {
        somaValores.valoresGerais += Number(item.valorGeral);
        somaValores.valoresJurosSoma += Number(item.valorJurosSoma);
        somaValores.valoresDescontoSubtrai += Number(item.valorDescontoSubtrai);
        somaValores.valoresMultaSoma += Number(item.valorMultaSoma);
        somaValores.valoresOutrasDespesasSoma += Number(
          item.valorOutrasDespesasSoma
        );
        somaValores.valoresOutrasDespesasSubtrai += Number(
          item.valorOutrasDespesasSubtrai
        );
        somaValores.valoresDespesasCartaoSubtrai += Number(
          item.valorDespesasCartaoSubtrai
        );
        somaValores.valoresDespesasCartorioSoma += Number(
          item.valorDespesasCartorioSoma
        );
        somaValores.valoresDespesasEnvioSoma += Number(
          item.valorDespesasEnvioSoma
        );
        somaValores.valoresComissoesSoma += Number(item.valorComissoesSoma);
        somaValores.valoresDespesasEnvioSubtrai += Number(
          item.valorDespesasEnvioSubtrai
        );
        somaValores.valoresComissoesSubtrai += Number(
          item.valorComissoesSubtrai
        );
      });
    }

    return somaValores;
  }, [formRefLista]);

  const handleObterValoresParcela = useCallback(async () => {
    const listaContaParcela =
      formPrincipal.formRef.current?.getFieldValue('listaContaParcela');

    const somaValores = await handleObterSomaCamposLiquidacao();
    const dadosPadrao = {
      valorGeral:
        listaContaParcela[indexParcela].valorParcela -
        somaValores.valoresGerais,
      valorJurosSoma:
        listaContaParcela[indexParcela].valorJurosSoma -
        somaValores.valoresJurosSoma,
      valorDescontoSubtrai:
        listaContaParcela[indexParcela].valorDescontoSubtrai -
        somaValores.valoresDescontoSubtrai,
      valorMultaSoma:
        listaContaParcela[indexParcela].valorMultaSoma -
        somaValores.valoresMultaSoma,
      valorOutrasDespesasSubtrai:
        listaContaParcela[indexParcela].valorOutrasDespesasSubtrai -
        somaValores.valoresOutrasDespesasSubtrai,
      valorOutrasDespesasSoma:
        listaContaParcela[indexParcela].valorOutrasDespesasSoma -
        somaValores.valoresOutrasDespesasSoma,
      valorDespesasCartaoSubtrai:
        listaContaParcela[indexParcela].valorDespesasCartaoSubtrai -
        somaValores.valoresDespesasCartaoSubtrai,
      valorDespesasCartorioSoma:
        listaContaParcela[indexParcela].valorDespesasCartorioSoma -
        somaValores.valoresDespesasCartorioSoma,
      valorDespesasEnvioSoma:
        listaContaParcela[indexParcela].valorDespesasEnvioSoma -
        somaValores.valoresDespesasEnvioSoma,
      valorComissoesSoma:
        listaContaParcela[indexParcela].valorComissoesSoma -
        somaValores.valoresComissoesSoma,
      valorDespesasEnvioSubtrai:
        listaContaParcela[indexParcela].valorDespesasEnvioSubtrai -
        somaValores.valoresDespesasEnvioSubtrai,
      valorComissoesSubtrai:
        listaContaParcela[indexParcela].valorComissoesSubtrai -
        somaValores.valoresComissoesSubtrai,
      formaCalculoJurosManual: !(
        parametros.JurosAoMesPadraoParaContasEmAtraso > 0
      ),
    };

    handleCarregarDados({
      dadosPadrao,
    });
  }, [
    formPrincipal.formRef,
    handleCarregarDados,
    handleObterSomaCamposLiquidacao,
    indexParcela,
    parametros.JurosAoMesPadraoParaContasEmAtraso,
  ]);

  const handleAlterarCamposParcela = useCallback(async () => {
    const listaContaParcela: IContaParcelaValoresInserir[] =
      formPrincipal.formRef.current?.getFieldValue('listaContaParcela');

    const somaValores = await handleObterSomaCamposLiquidacao();

    if (
      somaValores.valoresJurosSoma >
      listaContaParcela[indexParcela].valorJurosSoma
    ) {
      formRefLista.current?.setFieldValue(
        'valorJurosSoma',
        somaValores.valoresJurosSoma
      );
      listaContaParcela[indexParcela].valorJurosSoma =
        somaValores.valoresJurosSoma;
    }

    if (
      somaValores.valoresDescontoSubtrai >
      listaContaParcela[indexParcela].valorDescontoSubtrai
    ) {
      formRefLista.current?.setFieldValue(
        'valorDescontoSubtrai',
        somaValores.valoresDescontoSubtrai
      );
      listaContaParcela[indexParcela].valorDescontoSubtrai =
        somaValores.valoresDescontoSubtrai;
    }

    if (
      somaValores.valoresMultaSoma >
      listaContaParcela[indexParcela].valorMultaSoma
    ) {
      formRefLista.current?.setFieldValue(
        'valorMultaSoma',
        somaValores.valoresMultaSoma
      );
      listaContaParcela[indexParcela].valorMultaSoma =
        somaValores.valoresMultaSoma;
    }

    if (
      somaValores.valoresOutrasDespesasSoma >
      listaContaParcela[indexParcela].valorOutrasDespesasSoma
    ) {
      formRefLista.current?.setFieldValue(
        'valorOutrasDespesasSoma',
        somaValores.valoresOutrasDespesasSoma
      );
      listaContaParcela[indexParcela].valorOutrasDespesasSoma =
        somaValores.valoresOutrasDespesasSoma;
    }

    if (
      somaValores.valoresOutrasDespesasSubtrai >
      listaContaParcela[indexParcela].valorOutrasDespesasSubtrai
    ) {
      formRefLista.current?.setFieldValue(
        'valorOutrasDespesasSubtrai',
        somaValores.valoresOutrasDespesasSubtrai
      );
      listaContaParcela[indexParcela].valorOutrasDespesasSubtrai =
        somaValores.valoresOutrasDespesasSubtrai;
    }

    if (
      somaValores.valoresDespesasCartaoSubtrai >
      listaContaParcela[indexParcela].valorDespesasCartaoSubtrai
    ) {
      formRefLista.current?.setFieldValue(
        'valorDespesasCartaoSubtrai',
        somaValores.valoresDespesasCartaoSubtrai
      );
      listaContaParcela[indexParcela].valorDespesasCartaoSubtrai =
        somaValores.valoresDespesasCartaoSubtrai;
    }

    if (
      somaValores.valoresDespesasCartorioSoma >
      listaContaParcela[indexParcela].valorDespesasCartorioSoma
    ) {
      formRefLista.current?.setFieldValue(
        'valorDespesasCartorioSoma',
        somaValores.valoresDespesasCartorioSoma
      );
      listaContaParcela[indexParcela].valorDespesasCartorioSoma =
        somaValores.valoresDespesasCartorioSoma;
    }

    if (
      somaValores.valoresDespesasEnvioSoma >
      listaContaParcela[indexParcela].valorDespesasEnvioSoma
    ) {
      formRefLista.current?.setFieldValue(
        'valorDespesasEnvioSoma',
        somaValores.valoresDespesasEnvioSoma
      );
      listaContaParcela[indexParcela].valorDespesasEnvioSoma =
        somaValores.valoresDespesasEnvioSoma;
    }

    if (
      somaValores.valoresComissoesSoma >
      listaContaParcela[indexParcela].valorComissoesSoma
    ) {
      formRefLista.current?.setFieldValue(
        'valorComissoesSoma',
        somaValores.valoresComissoesSoma
      );
      listaContaParcela[indexParcela].valorComissoesSoma =
        somaValores.valoresComissoesSoma;
    }

    if (
      somaValores.valoresDespesasEnvioSubtrai >
      listaContaParcela[indexParcela].valorDespesasEnvioSubtrai
    ) {
      formRefLista.current?.setFieldValue(
        'valorDespesasEnvioSubtrai',
        somaValores.valoresDespesasEnvioSubtrai
      );
      listaContaParcela[indexParcela].valorDespesasEnvioSubtrai =
        somaValores.valoresDespesasEnvioSubtrai;
    }

    if (
      somaValores.valoresComissoesSubtrai >
      listaContaParcela[indexParcela].valorComissoesSubtrai
    ) {
      formRefLista.current?.setFieldValue(
        'valorComissoesSubtrai',
        somaValores.valoresComissoesSubtrai
      );
      listaContaParcela[indexParcela].valorComissoesSubtrai =
        somaValores.valoresComissoesSubtrai;
    }

    const tipoAdiantamento =
      formPrincipal.formRef.current?.getFieldValue('tipoAdiantamento');

    if (somaValores.valoresGerais > 0 && !tipoAdiantamento) {
      formPrincipal.formRef.current?.setFieldValue(
        'tipoAdiantamento',
        TipoAdiantamentoEnum.antecipacao
      );
      formPrincipal.refresh();
    }

    const listaDados = formRefLista.current?.getFieldValue(
      'listaContaParcelaLiquidacao'
    );

    if (listaDados.length > 0) {
      const valorTotalMovimentos = listaDados
        .reduce((acc: any, val: any) => {
          acc += Number(val.valor);
          return acc;
        }, 0)
        .Arredondar();

      formRefLista.current?.setFieldValue(
        'valorTotalMovimentos',
        valorTotalMovimentos
      );

      if (
        Number(listaContaParcela[indexParcela].valorTotalParcela) ===
        valorTotalMovimentos
      ) {
        formRefLista.current?.setFieldValue(
          'situacao',
          SituacaoContaEnum.liquidada
        );
        listaContaParcela[indexParcela].situacao = SituacaoContaEnum.liquidada;
      } else {
        formRefLista.current?.setFieldValue(
          'situacao',
          SituacaoContaEnum.parcialmenteLiquidada
        );
        listaContaParcela[indexParcela].situacao =
          SituacaoContaEnum.parcialmenteLiquidada;
      }
    }
  }, [
    formPrincipal,
    formRefLista,
    handleObterSomaCamposLiquidacao,
    indexParcela,
  ]);

  useEffect(() => {
    handleCarregarDados();
  }, [handleCarregarDados]);

  useEffect(() => {
    if (categoriaValida) handleInserirListaContaParcelaLiquidacaoCategoria();
    if (!idDetalheRegistro) handleObterValoresParcela();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickSalvar = useCallback(async (): Promise<void> => {
    const listaContaParcela =
      formPrincipal.formRef.current?.getFieldValue('listaContaParcela');
    const data = formRefDetalhe.current?.getDataDuplicar();
    data.indexParcela = indexParcela;
    data.indexMovimentoPortador =
      listaContaParcela[indexParcela].listaContaParcelaLiquidacao.length;

    const { erro } = await handleSubmit(data, formRefDetalhe.current);
    if (erro) return;

    await handleAlterarCamposParcela();

    refresh();

    if (onSalvarFormModal) onSalvarFormModal();
  }, [
    formPrincipal.formRef,
    formRefDetalhe,
    handleAlterarCamposParcela,
    handleSubmit,
    indexParcela,
    onSalvarFormModal,
    refresh,
  ]);

  return (
    <JanelaDetalhe
      titulo="Liquidação da Parcela"
      tamanho="xl"
      onFecharFormModal={onFecharFormModal}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <FormCia ref={formRefDetalhe}>
        <Modal.Body>
          <Container>
            <Tabs id={v4()} defaultActiveKey="geral">
              <Tab eventKey="geral" title="Geral">
                <GeralTab
                  indexParcela={indexParcela}
                  indexParcelaLiquidacao={indexParcelaLiquidacao}
                  bloquearCamposReversao={revertida}
                />
              </Tab>

              <Tab
                eventKey="categoria"
                title="Categoria"
                tabClassName={categoriaValida ? '' : 'hidden'}
              >
                <CategoriaTab />
              </Tab>
            </Tabs>
          </Container>
        </Modal.Body>
      </FormCia>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={onFecharFormModal}
              disabled={loading}
            >
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
                <span style={{ marginLeft: 10 }}>Voltar</span>
              </TextoLoading>
            </button>

            <Button
              style={{
                marginLeft: 15,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
              className="btn-padrao btn-verde-claro btn-adicionar"
              type="button"
              onClick={handleClickSalvar}
              disabled={
                (idDetalheRegistro ? !permissao?.altera : !permissao?.inclui) ||
                loading ||
                revertida
              }
            >
              <TextoLoading loading={loading}>
                <BiSave />
                <span style={{ marginLeft: 10 }}>
                  {idDetalheRegistro ? 'Salvar' : 'Adicionar'}
                </span>
              </TextoLoading>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </JanelaDetalhe>
  );
};

export default ContaParcelaLiquidacaoDetalhe;
